import { InMemoryCache, makeVar } from '@apollo/client'
import { User } from '../types/graphql'

export interface successErrorType {
    place?: string
    message: string
}

export const successVar = makeVar<successErrorType | null>(null)
export const errorsVar = makeVar<successErrorType[]>([])
export const userVar = makeVar<User | undefined | null>(undefined)
export const unviewedMessagesVar = makeVar<number | undefined>(0)
export const selectedGroupDateVar = makeVar<string>('')
export const trainingVar = makeVar<string>('')
export const startGroupDateVar = makeVar<string>('')

/*export const userEditVar = makeVar<EditUserMutationVariables | null>(null)
export const operationEditVar = makeVar<EditOperationMutationVariables | null>(null)
export const eventEditVar = makeVar<EditEventMutationVariables | null>(null)
export const eventDeleteVar = makeVar<DeleteEventMutationVariables | null>(null)*/

export const cache: InMemoryCache = new InMemoryCache({
    /*typePolicies: {
        Event: {
            keyFields: ['date', 'time']
        }
    }*/
})