import React, { useEffect, useState } from 'react'
import { ReactComponent as ButtonTopIcon } from '../../../assets/images/button-top.svg'
import './styles.css'

export const scrollToTop = (value:number) => window.scrollY > value
export const ScrollingTop: React.FC = () => {
    const [isBackToTop, setIsBackToTop] = useState<boolean>(false);
    const handleScrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener( 'scroll', () => setIsBackToTop(scrollToTop(100)) );
    }, []);
    return (
            <div> 
                {isBackToTop ?
                <button
                        data-testid="button-scroll-to-top"
                        className="button_up"
                        onClick={handleScrollToTop}
                    >
                        <ButtonTopIcon/>
                </button> : null }   
            </div>
    )
}
export default ScrollingTop
