import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/ru'
import Wrapper from '../../../components/common/Wrapper';
import ScrollingTop from '../../../components/UsersComponents/ScrollingTop';
import { usePageQuery, useUserLectionsQuery } from '../../../types/graphql'
import { useMediaQuery } from 'react-responsive';
import './styles.css'
moment.tz.setDefault('Europe/Tallinn')

export const Instructions: React.FC = () => {
    const { data, loading } = usePageQuery({
       variables: {
          name: 'instructions'
       }
    })

    const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })
    const selected_group_id = localStorage.getItem("selectedGroupId")
    const { data: lectionsData, loading: lectionLoading } = useUserLectionsQuery({
        variables: {
            groupId: selected_group_id || ''
        }
    })

    let moduleId : string= 'none'
    lectionsData?.userLections?.lections?.forEach(el => {
        if (moment().isBefore(moment(el?.date).add(7, 'days'))) {
            moduleId = 'm' + el?.module_id?.slice(6) || ''
        }
    }) 
    let textHtml = data?.page?.html || ''
    let menu = []
    let index = textHtml.lastIndexOf('<h2 id=')
    while (index >=0) {
        const menuItem = textHtml.slice(index)
        const indexId = menuItem.indexOf('id=') + 4
        const menuItemId = menuItem.slice(indexId, indexId + 2)
        let menuItemName = menuItem.slice(menuItem.indexOf('>') + 1)
        menuItemName = menuItemName.slice(0, menuItemName.indexOf('<'))
        menu.unshift({
            "menuItemId": menuItemId,
            "menuItemName": menuItemName,
            "menuItemContent": menuItem
        })
        textHtml = textHtml?.slice(0, index)
        index = textHtml.lastIndexOf('<h2 id="')
    }
    let availableHtml = ''
    menu.forEach(el => {
        if (el.menuItemId[0] !== 'm' || el.menuItemId === moduleId) {
            availableHtml += el.menuItemContent
        }
    })
    
    if (loading || lectionLoading) return <div className="preloader"/>

    return (        
        <Wrapper>
            {data?.page && lectionsData?.userLections && (
                <div className="instructions typography">
                    <div className="instructions__wrap">
                        <div className="instructions__left">
                            <div dangerouslySetInnerHTML={{ __html: availableHtml || '' }} />
                        </div>
                        <div className={ isDesktop ? 'instructions__right' : 'instructions__top' } data-testid='menu-wrap'>
                            <div data-testid="menu" className="instructions__menu">
                                {menu.map(el =>  
                                    (el.menuItemId[0] !== 'm' || el.menuItemId === moduleId) &&
                                        <a href={`#${el.menuItemId}`} key={el.menuItemId}>
                                            <div className="instructions__menu-item">
                                                {el.menuItemName}
                                            </div>
                                        </a>
                                )}
                            </div>
                        </div>
                    </div>
                    { !isDesktop && <ScrollingTop /> }
                </div>
            )}
        </Wrapper>
    )
}

export default Instructions
