import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AdminUserGroup = {
  __typename?: 'AdminUserGroup';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type AdminUserLectionResults = {
  __typename?: 'AdminUserLectionResults';
  lection_id?: Maybe<Scalars['ID']>;
  lection_result?: Maybe<Array<Maybe<LectionTaskResults>>>;
  module_id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  theme?: Maybe<Scalars['String']>;
};

export type Characteristics = {
  __typename?: 'Characteristics';
  advantages?: Maybe<Array<Maybe<Scalars['String']>>>;
  best?: Maybe<Scalars['Boolean']>;
  disadvantages?: Maybe<Array<Maybe<Scalars['String']>>>;
  worst?: Maybe<Scalars['Boolean']>;
};

export type CreatedDialog = {
  __typename?: 'CreatedDialog';
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<Member>>>;
  num?: Maybe<Scalars['Int']>;
};

export type CupBest = {
  __typename?: 'CupBest';
  lection_id?: Maybe<Scalars['String']>;
  lection_num?: Maybe<Scalars['Int']>;
};

export type Dialog = {
  __typename?: 'Dialog';
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<Member>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  num?: Maybe<Scalars['Int']>;
};

export type Dialog1 = {
  __typename?: 'Dialog1';
  nextUserStep2?: Maybe<Scalars['String']>;
  userStep1?: Maybe<M3TaskResult>;
  userStep3?: Maybe<M3TaskResult>;
};

export type Dialog2 = {
  __typename?: 'Dialog2';
  prevUserStep1?: Maybe<Scalars['String']>;
  prevUserStep3?: Maybe<Scalars['String']>;
  userStep2?: Maybe<M3TaskResult>;
};

export type DialogOnClient = {
  __typename?: 'DialogOnClient';
  amountAllMessages?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastMessage?: Maybe<LastMessage>;
  members?: Maybe<Array<Maybe<Member>>>;
  num?: Maybe<Scalars['Int']>;
  unviewedMessages?: Maybe<Scalars['Int']>;
};

export type DialogPages = {
  __typename?: 'DialogPages';
  results?: Maybe<Array<Maybe<DialogOnClient>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Diplom = {
  __typename?: 'Diplom';
  diploma?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type Evaluation = {
  __typename?: 'Evaluation';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  evaluation_comments?: Maybe<Array<Maybe<EvaluationComments>>>;
  myEvaluation?: Maybe<MyEvaluation>;
};

export type EvaluationComments = {
  __typename?: 'EvaluationComments';
  comment?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type Group = {
  __typename?: 'Group';
  end_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
  users_count?: Maybe<Scalars['Int']>;
};

export type GroupAnswer = {
  __typename?: 'GroupAnswer';
  answer?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type GroupEndDate = {
  __typename?: 'GroupEndDate';
  end_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type GroupLection = {
  __typename?: 'GroupLection';
  id?: Maybe<Scalars['ID']>;
  module_id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  usersWithAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  usersWithResults?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupLections = {
  __typename?: 'GroupLections';
  group?: Maybe<Group>;
  lections?: Maybe<Array<Maybe<GroupLection>>>;
};

export type GroupList = {
  __typename?: 'GroupList';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type GroupPages = {
  __typename?: 'GroupPages';
  groups?: Maybe<Array<Maybe<Group>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GroupStatistics = {
  __typename?: 'GroupStatistics';
  lectionsStatistics?: Maybe<Array<Maybe<LectionStatistics>>>;
  usersRating?: Maybe<Array<Maybe<Rating>>>;
};

export type LastMessage = {
  __typename?: 'LastMessage';
  author_id?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Lection = {
  __typename?: 'Lection';
  audio?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  module_id?: Maybe<Scalars['String']>;
  module_params?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  points_total?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Maybe<LectionTask>>>;
  theme?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type LectionResult = {
  __typename?: 'LectionResult';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<UsersComments>>>;
  cup_best_answer?: Maybe<Scalars['Boolean']>;
  cup_best_comment?: Maybe<Scalars['Boolean']>;
  evaluation_comments?: Maybe<Evaluation>;
  evaluations?: Maybe<Array<Maybe<UsersEvaluations>>>;
  points?: Maybe<Scalars['Int']>;
  points_activity?: Maybe<Scalars['Int']>;
  points_evaluation?: Maybe<Scalars['Int']>;
};

export type LectionStatistics = {
  __typename?: 'LectionStatistics';
  allSteps?: Maybe<Scalars['Int']>;
  averageResult?: Maybe<Scalars['Int']>;
  lectionId?: Maybe<Scalars['ID']>;
  maxResult?: Maybe<Scalars['Int']>;
  minResult?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  step1?: Maybe<Scalars['Int']>;
  step2?: Maybe<Scalars['Int']>;
  step3?: Maybe<Scalars['Int']>;
  step4?: Maybe<Scalars['Int']>;
};

export type LectionTask = {
  __typename?: 'LectionTask';
  date_end?: Maybe<Scalars['String']>;
  date_start?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  reward?: Maybe<LectionTaskReward>;
  status?: Maybe<Scalars['String']>;
};

export type LectionTaskResults = {
  __typename?: 'LectionTaskResults';
  cup_best_answer?: Maybe<Scalars['Boolean']>;
  cup_best_comment?: Maybe<Scalars['Boolean']>;
  cup_best_phrase?: Maybe<Scalars['Boolean']>;
  num?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  points_activity?: Maybe<Scalars['Int']>;
};

export type LectionTaskReward = {
  __typename?: 'LectionTaskReward';
  cup_best_answer?: Maybe<Scalars['Boolean']>;
  cup_best_comment?: Maybe<Scalars['Boolean']>;
  cup_best_phrase?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  points_activity?: Maybe<Scalars['Int']>;
};

export type LectionsPlus1 = {
  __typename?: 'LectionsPlus1';
  lections?: Maybe<Array<Maybe<Lection>>>;
  next_lection_date?: Maybe<Scalars['String']>;
};

export type M3LectionResult = {
  __typename?: 'M3LectionResult';
  cup_best_phrase?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dialog1?: Maybe<Dialog1>;
  dialog2?: Maybe<Dialog2>;
  points?: Maybe<Scalars['Int']>;
  points_activity?: Maybe<Scalars['Int']>;
};

export type M3Task1 = {
  __typename?: 'M3Task1';
  step1Answer?: Maybe<Scalars['String']>;
};

export type M3Task2 = {
  __typename?: 'M3Task2';
  step1Answer?: Maybe<Scalars['String']>;
  step2Answer?: Maybe<Scalars['String']>;
};

export type M3Task3 = {
  __typename?: 'M3Task3';
  step1Answer?: Maybe<Scalars['String']>;
  step2Answer?: Maybe<Scalars['String']>;
  step3Answer?: Maybe<Scalars['String']>;
};

export type M3Task4 = {
  __typename?: 'M3Task4';
  advantages?: Maybe<Array<Maybe<Scalars['String']>>>;
  answer?: Maybe<Scalars['String']>;
  best?: Maybe<Scalars['Boolean']>;
  disadvantages?: Maybe<Array<Maybe<Scalars['String']>>>;
  step?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
  worst?: Maybe<Scalars['Boolean']>;
};

export type M3Task4Input = {
  advantages: Array<InputMaybe<Scalars['String']>>;
  best: Scalars['Boolean'];
  disadvantages: Array<InputMaybe<Scalars['String']>>;
  step: Scalars['Int'];
  user_id: Scalars['ID'];
  worst: Scalars['Boolean'];
};

export type M3TaskResult = {
  __typename?: 'M3TaskResult';
  answer?: Maybe<Scalars['String']>;
  characteristics?: Maybe<Array<Maybe<Characteristics>>>;
  points?: Maybe<Scalars['Int']>;
};

export type M4Message = {
  __typename?: 'M4Message';
  color?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is_group_answer?: Maybe<Scalars['Boolean']>;
  is_proposed?: Maybe<Scalars['Boolean']>;
  is_voted?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
  votes?: Maybe<Scalars['Int']>;
};

export type M4Result = {
  __typename?: 'M4Result';
  answer?: Maybe<Scalars['String']>;
  answer_characteristics?: Maybe<Array<Maybe<M4Task2>>>;
  answer_points?: Maybe<Scalars['Int']>;
  group_answer?: Maybe<Scalars['String']>;
  group_answer_points?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  points_activity?: Maybe<Scalars['Int']>;
  team_work_characteristics?: Maybe<Array<Maybe<M4Task2>>>;
  team_work_points?: Maybe<Scalars['Int']>;
};

export type M4Task1 = {
  __typename?: 'M4Task1';
  answer?: Maybe<Scalars['String']>;
};

export type M4Task2 = {
  __typename?: 'M4Task2';
  advantages?: Maybe<Array<Maybe<Scalars['String']>>>;
  answer?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  disadvantages?: Maybe<Array<Maybe<Scalars['String']>>>;
  points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type M4Task2Input = {
  advantages: Array<InputMaybe<Scalars['String']>>;
  disadvantages: Array<InputMaybe<Scalars['String']>>;
  points?: InputMaybe<Scalars['Int']>;
  user_id: Scalars['ID'];
};

export type M4Task3 = {
  __typename?: 'M4Task3';
  count_unviewed?: Maybe<Scalars['Int']>;
  is_chat_closed?: Maybe<Scalars['Boolean']>;
  last_viewed?: Maybe<Scalars['ID']>;
  max_votes?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<M4Message>>>;
  userColor?: Maybe<Scalars['String']>;
};

export type M4Task4 = {
  __typename?: 'M4Task4';
  characteristics?: Maybe<Array<Maybe<M4Task2>>>;
  group_answers?: Maybe<Array<Maybe<GroupAnswer>>>;
};

export type M4Task4Input = {
  points?: InputMaybe<Scalars['Int']>;
  user_id: Scalars['ID'];
};

export type Member = {
  __typename?: 'Member';
  membersCode?: Maybe<Scalars['String']>;
  membersName?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  attachements?: Maybe<Array<Maybe<Scalars['String']>>>;
  author_id?: Maybe<Scalars['ID']>;
  author_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  dialog_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
};

export type Module2Result = {
  __typename?: 'Module2Result';
  date?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAttachement?: Maybe<Scalars['String']>;
  addDiplom?: Maybe<UserGroup>;
  addM1Task1?: Maybe<OkMessage>;
  addM1Task2?: Maybe<OkMessage>;
  addM1Task3?: Maybe<OkMessage>;
  addM1Task4?: Maybe<OkMessage>;
  addM3Task4?: Maybe<OkMessage>;
  addM3Tasks?: Maybe<OkMessage>;
  addM4Task1?: Maybe<OkMessage>;
  addM4Task2?: Maybe<OkMessage>;
  addM4Task4?: Maybe<OkMessage>;
  adminUpdateUser?: Maybe<User>;
  createDialog?: Maybe<Dialog>;
  createEmptyResults?: Maybe<Array<Maybe<Scalars['String']>>>;
  createGroup?: Maybe<Group>;
  createLection?: Maybe<TrainingLection>;
  createTraining?: Maybe<Training>;
  createUser?: Maybe<User>;
  deleteAttachements?: Maybe<Array<Maybe<Scalars['String']>>>;
  deleteGroup?: Maybe<OkMessage>;
  deleteLection?: Maybe<OkMessage>;
  deleteMessage?: Maybe<OkMessage>;
  deleteSavedAnswer?: Maybe<OkMessage>;
  deleteSavedMediaTime?: Maybe<OkMessage>;
  deleteUser?: Maybe<OkMessage>;
  editUserLectionResults?: Maybe<OkMessage>;
  moveLection?: Maybe<OkMessage>;
  payEveryPay?: Maybe<OkMessage>;
  register?: Maybe<Scalars['String']>;
  registerForTraining?: Maybe<User>;
  removeDiplom?: Maybe<UserGroup>;
  requestInvoice?: Maybe<OkMessage>;
  resendVerifyEmailCode?: Maybe<OkMessage>;
  resendVerifyPhoneCode?: Maybe<OkMessage>;
  restorePasswordConfirm?: Maybe<OkMessage>;
  saveAnswer?: Maybe<SavedAnswer>;
  saveMediaTime?: Maybe<SavedMediaTime>;
  sendM4Message?: Maybe<OkMessage>;
  sendMessage?: Maybe<Message>;
  updateGroup?: Maybe<Group>;
  updateLection?: Maybe<TrainingLection>;
  updateMessage?: Maybe<Message>;
  updatePage?: Maybe<OkMessage>;
  updateTraining?: Maybe<Training>;
  updateUser?: Maybe<User>;
  updateUserGroupPayment?: Maybe<UserGroup>;
  verifyEmail?: Maybe<User>;
  verifyPhone?: Maybe<User>;
  viewAllMessagesInDialog?: Maybe<OkMessage>;
  viewM4Message?: Maybe<OkMessage>;
  voteM4Message?: Maybe<OkMessage>;
};


export type MutationAddAttachementArgs = {
  attachement: Scalars['String'];
  dialog_id: Scalars['String'];
  message_id?: InputMaybe<Scalars['ID']>;
};


export type MutationAddDiplomArgs = {
  fileName: Scalars['String'];
  group_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationAddM1Task1Args = {
  answer: Scalars['String'];
  answer2: Scalars['String'];
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationAddM1Task2Args = {
  comments: Array<InputMaybe<Task2Comment>>;
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationAddM1Task3Args = {
  evaluation: Task3EvaluationInput;
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationAddM1Task4Args = {
  evaluation_comments: Array<InputMaybe<Task4EvaluationCommentInput>>;
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationAddM3Task4Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  results: Array<InputMaybe<M3Task4Input>>;
};


export type MutationAddM3TasksArgs = {
  answer: Scalars['String'];
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  task_num: Scalars['Int'];
};


export type MutationAddM4Task1Args = {
  answer: Scalars['String'];
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationAddM4Task2Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  results: Array<InputMaybe<M4Task2Input>>;
};


export type MutationAddM4Task4Args = {
  characteristics: Array<InputMaybe<M4Task2Input>>;
  group_answers: Array<InputMaybe<M4Task4Input>>;
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationAdminUpdateUserArgs = {
  active: Scalars['Boolean'];
  email: Scalars['String'];
  groups: Array<InputMaybe<Scalars['String']>>;
  name: Scalars['String'];
  notifications: NotificationsInput;
  phone: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreateDialogArgs = {
  members: Array<InputMaybe<Scalars['String']>>;
};


export type MutationCreateEmptyResultsArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type MutationCreateGroupArgs = {
  price: Scalars['Float'];
  start_date: Scalars['String'];
  training_id: Scalars['ID'];
};


export type MutationCreateLectionArgs = {
  audio: Scalars['String'];
  module_id: Scalars['String'];
  module_params: Scalars['String'];
  num: Scalars['Int'];
  theme: Scalars['String'];
  training_id: Scalars['ID'];
  video: Scalars['String'];
};


export type MutationCreateTrainingArgs = {
  info_url: Scalars['String'];
  material_page: Scalars['String'];
  program_page: Scalars['String'];
  short_theme: Scalars['String'];
  sub_theme: Scalars['String'];
  theme: Scalars['String'];
};


export type MutationCreateUserArgs = {
  active: Scalars['Boolean'];
  email: Scalars['String'];
  groups: Array<InputMaybe<Scalars['String']>>;
  name: Scalars['String'];
  notifications: NotificationsInput;
  phone: Scalars['String'];
};


export type MutationDeleteAttachementsArgs = {
  attachementLinks: Array<InputMaybe<Scalars['String']>>;
  message_id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteGroupArgs = {
  group_id: Scalars['ID'];
};


export type MutationDeleteLectionArgs = {
  lection_id: Scalars['ID'];
  training_id: Scalars['ID'];
};


export type MutationDeleteMessageArgs = {
  attachements: Array<InputMaybe<Scalars['String']>>;
  message_id: Scalars['ID'];
};


export type MutationDeleteSavedAnswerArgs = {
  answer_id: Scalars['ID'];
};


export type MutationDeleteSavedMediaTimeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationEditUserLectionResultsArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  results: TaskResultInput;
  task_num: Scalars['Int'];
  user_id: Scalars['ID'];
};


export type MutationMoveLectionArgs = {
  direction?: InputMaybe<Scalars['String']>;
  lection_id: Scalars['ID'];
  training_id: Scalars['ID'];
};


export type MutationPayEveryPayArgs = {
  group_id: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  pass: Scalars['String'];
  phone: Scalars['String'];
  subscribed: Scalars['Boolean'];
};


export type MutationRegisterForTrainingArgs = {
  group_id: Scalars['ID'];
};


export type MutationRemoveDiplomArgs = {
  filename: Scalars['String'];
  group_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationRequestInvoiceArgs = {
  group_id: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationRestorePasswordConfirmArgs = {
  code: Scalars['Int'];
  email: Scalars['String'];
  newPass: Scalars['String'];
};


export type MutationSaveAnswerArgs = {
  content: Scalars['String'];
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  task_num: Scalars['Int'];
};


export type MutationSaveMediaTimeArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  time: Scalars['Int'];
};


export type MutationSendM4MessageArgs = {
  group_id: Scalars['ID'];
  is_proposed: Scalars['Boolean'];
  lection_id: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationSendMessageArgs = {
  attachementsLinks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialog_id: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  group_id: Scalars['ID'];
  price: Scalars['Float'];
  start_date: Scalars['String'];
  training_id: Scalars['ID'];
};


export type MutationUpdateLectionArgs = {
  audio: Scalars['String'];
  lection_id: Scalars['ID'];
  module_id: Scalars['String'];
  module_params: Scalars['String'];
  num: Scalars['Int'];
  theme: Scalars['String'];
  training_id: Scalars['ID'];
  video: Scalars['String'];
};


export type MutationUpdateMessageArgs = {
  attachementsToDelete: Array<InputMaybe<Scalars['String']>>;
  message_id: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationUpdatePageArgs = {
  html: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateTrainingArgs = {
  info_url: Scalars['String'];
  material_page: Scalars['String'];
  program_page: Scalars['String'];
  short_theme: Scalars['String'];
  sub_theme: Scalars['String'];
  theme: Scalars['String'];
  training_id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  notifications: NotificationsInput;
  phone: Scalars['String'];
};


export type MutationUpdateUserGroupPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  group_id: Scalars['ID'];
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  user_id: Scalars['ID'];
};


export type MutationVerifyEmailArgs = {
  code: Scalars['Int'];
  email: Scalars['String'];
};


export type MutationVerifyPhoneArgs = {
  code: Scalars['Int'];
  phone: Scalars['String'];
};


export type MutationViewAllMessagesInDialogArgs = {
  dialog_id: Scalars['ID'];
};


export type MutationViewM4MessageArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  message_id: Scalars['ID'];
};


export type MutationVoteM4MessageArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  message_id: Scalars['ID'];
  user_id: Scalars['ID'];
};

export type MyEvaluation = {
  __typename?: 'MyEvaluation';
  advantages?: Maybe<Scalars['String']>;
  disadvantages?: Maybe<Scalars['String']>;
};

export type NextPrevLectionIds = {
  __typename?: 'NextPrevLectionIds';
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
};

export type Notifications = {
  __typename?: 'Notifications';
  email: Scalars['Boolean'];
};

export type NotificationsInput = {
  email: Scalars['Boolean'];
};

export type OkMessage = {
  __typename?: 'OkMessage';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  avg?: Maybe<Scalars['Float']>;
  avgMy?: Maybe<Scalars['Float']>;
  avgTen?: Maybe<Scalars['Float']>;
  diploma?: Maybe<Array<Maybe<Scalars['Int']>>>;
  diplomaGold?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adminUserGroups?: Maybe<Array<Maybe<AdminUserGroup>>>;
  adminUserLectionResults?: Maybe<Array<Maybe<AdminUserLectionResults>>>;
  allUnviewedMesseges?: Maybe<Scalars['Int']>;
  countryFlagCode?: Maybe<Scalars['String']>;
  dialog?: Maybe<Dialog>;
  dialogs?: Maybe<DialogPages>;
  group?: Maybe<Group>;
  groupEndDate?: Maybe<GroupEndDate>;
  groupLectionsList?: Maybe<GroupLections>;
  groupList?: Maybe<Array<Maybe<GroupList>>>;
  groupStatistics?: Maybe<GroupStatistics>;
  groups?: Maybe<GroupPages>;
  lections?: Maybe<Array<Maybe<TrainingLection>>>;
  login?: Maybe<Scalars['String']>;
  loginUserForAdmin?: Maybe<Scalars['String']>;
  m1Result?: Maybe<LectionResult>;
  m1Task1?: Maybe<Task1>;
  m1Task2?: Maybe<Array<Maybe<Task2>>>;
  m1Task3?: Maybe<Task3>;
  m1Task4?: Maybe<Task4>;
  m2Result?: Maybe<Array<Maybe<Module2Result>>>;
  m3Result?: Maybe<M3LectionResult>;
  m3Task1?: Maybe<M3Task1>;
  m3Task2?: Maybe<M3Task2>;
  m3Task3?: Maybe<M3Task3>;
  m3Task4?: Maybe<Array<Maybe<M3Task4>>>;
  m4Result?: Maybe<M4Result>;
  m4Task1?: Maybe<M4Task1>;
  m4Task2?: Maybe<Array<Maybe<M4Task2>>>;
  m4Task3?: Maybe<M4Task3>;
  m4Task4?: Maybe<M4Task4>;
  me?: Maybe<User>;
  messageToSchool?: Maybe<OkMessage>;
  nextPrevLectionIds?: Maybe<NextPrevLectionIds>;
  page?: Maybe<Page>;
  pages?: Maybe<Array<Maybe<Page>>>;
  progress?: Maybe<TrainingProgress>;
  refreshToken?: Maybe<Scalars['String']>;
  restorePassword?: Maybe<OkMessage>;
  savedAnswer?: Maybe<SavedAnswer>;
  savedMediaTime?: Maybe<SavedMediaTime>;
  training?: Maybe<Training>;
  trainingPage?: Maybe<Scalars['String']>;
  trainings?: Maybe<Array<Maybe<Training>>>;
  trainingsForRegister?: Maybe<Array<Maybe<TrainingForRegister>>>;
  userDiploms?: Maybe<Array<Maybe<Diplom>>>;
  userGroup?: Maybe<UserGroup>;
  userInfo?: Maybe<User>;
  userLection?: Maybe<Lection>;
  userLections?: Maybe<LectionsPlus1>;
  userStatistic?: Maybe<Statistic>;
  users?: Maybe<UserPages>;
};


export type QueryAdminUserGroupsArgs = {
  user_id: Scalars['ID'];
};


export type QueryAdminUserLectionResultsArgs = {
  group_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type QueryCountryFlagCodeArgs = {
  userIp: Scalars['String'];
};


export type QueryDialogArgs = {
  id: Scalars['ID'];
};


export type QueryDialogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGroupEndDateArgs = {
  id: Scalars['ID'];
};


export type QueryGroupLectionsListArgs = {
  group_id: Scalars['ID'];
};


export type QueryGroupStatisticsArgs = {
  group_id: Scalars['ID'];
};


export type QueryGroupsArgs = {
  date?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryLectionsArgs = {
  training_id: Scalars['ID'];
};


export type QueryLoginArgs = {
  email: Scalars['String'];
  pass: Scalars['String'];
};


export type QueryLoginUserForAdminArgs = {
  userId: Scalars['ID'];
};


export type QueryM1ResultArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM1Task1Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM1Task2Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM1Task3Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM1Task4Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM2ResultArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM3ResultArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM3Task1Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM3Task2Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM3Task3Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM3Task4Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM4ResultArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM4Task1Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM4Task2Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryM4Task3Args = {
  group_id: Scalars['ID'];
  last_viewed?: InputMaybe<Scalars['ID']>;
  lection_id: Scalars['ID'];
};


export type QueryM4Task4Args = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryMessageToSchoolArgs = {
  amountToPay: Scalars['String'];
  date: Scalars['String'];
  email: Scalars['String'];
  file?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  message_requisites?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  training: Scalars['String'];
};


export type QueryNextPrevLectionIdsArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryPageArgs = {
  name: Scalars['String'];
};


export type QueryProgressArgs = {
  group_id: Scalars['ID'];
};


export type QueryRefreshTokenArgs = {
  accessToken: Scalars['String'];
};


export type QueryRestorePasswordArgs = {
  email: Scalars['String'];
};


export type QuerySavedAnswerArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
  task_num: Scalars['Int'];
};


export type QuerySavedMediaTimeArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryTrainingArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingPageArgs = {
  group_id: Scalars['ID'];
  page_id: Scalars['String'];
};


export type QueryUserGroupArgs = {
  group_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type QueryUserInfoArgs = {
  id: Scalars['ID'];
};


export type QueryUserLectionArgs = {
  group_id: Scalars['ID'];
  lection_id: Scalars['ID'];
};


export type QueryUserLectionsArgs = {
  group_id: Scalars['ID'];
};


export type QueryUserStatisticArgs = {
  group_id: Scalars['ID'];
};


export type QueryUsersArgs = {
  groupId?: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type Rating = {
  __typename?: 'Rating';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

export type SavedAnswer = {
  __typename?: 'SavedAnswer';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lection_id?: Maybe<Scalars['ID']>;
  task_num?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type SavedMediaTime = {
  __typename?: 'SavedMediaTime';
  group_id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lection_id?: Maybe<Scalars['ID']>;
  time?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type Statistic = {
  __typename?: 'Statistic';
  bestAnswer?: Maybe<Array<Maybe<CupBest>>>;
  bestComment?: Maybe<Array<Maybe<CupBest>>>;
  bestPhrase?: Maybe<Array<Maybe<CupBest>>>;
  myPoints?: Maybe<Scalars['Int']>;
  points?: Maybe<Array<Maybe<Point>>>;
  rankPlace?: Maybe<Scalars['Int']>;
  topTen?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Task1 = {
  __typename?: 'Task1';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

export type Task2 = {
  __typename?: 'Task2';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  my_comment?: Maybe<Scalars['String']>;
  my_points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Task2Comment = {
  comment: Scalars['String'];
  points: Scalars['Int'];
  user_id: Scalars['ID'];
};

export type Task3 = {
  __typename?: 'Task3';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Task3Evaluation>;
  user_id?: Maybe<Scalars['String']>;
};

export type Task3Evaluation = {
  __typename?: 'Task3Evaluation';
  advantages?: Maybe<Scalars['String']>;
  disadvantages?: Maybe<Scalars['String']>;
};

export type Task3EvaluationInput = {
  advantages: Scalars['String'];
  disadvantages: Scalars['String'];
  user_id: Scalars['ID'];
};

export type Task4 = {
  __typename?: 'Task4';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  evaluation_comments?: Maybe<Array<Maybe<Task4EvaluationComment>>>;
  groupUncompleteTask?: Maybe<Scalars['String']>;
};

export type Task4EvaluationComment = {
  __typename?: 'Task4EvaluationComment';
  advantages?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  disadvantages?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type Task4EvaluationCommentInput = {
  comment: Scalars['String'];
  points: Scalars['Int'];
  user_id: Scalars['ID'];
};

export type TaskComment = {
  __typename?: 'TaskComment';
  comment?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type TaskEvaluation = {
  __typename?: 'TaskEvaluation';
  advantages?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<TaskComment>>>;
  disadvantages?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type TaskResultInput = {
  cup_best_answer?: InputMaybe<Scalars['Boolean']>;
  cup_best_comment?: InputMaybe<Scalars['Boolean']>;
  cup_best_phrase?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  points_activity?: InputMaybe<Scalars['Int']>;
};

export type TaskResults = {
  __typename?: 'TaskResults';
  answer?: Maybe<Scalars['String']>;
  answer2?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<TaskComment>>>;
  evaluation?: Maybe<Array<Maybe<TaskEvaluation>>>;
  is_best?: Maybe<Scalars['Boolean']>;
};

export type Training = {
  __typename?: 'Training';
  group_coming?: Maybe<Scalars['Int']>;
  group_current?: Maybe<Scalars['Int']>;
  group_ended?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  info_url?: Maybe<Scalars['String']>;
  lection_count?: Maybe<Scalars['Int']>;
  material_page?: Maybe<Scalars['String']>;
  program_page?: Maybe<Scalars['String']>;
  short_theme?: Maybe<Scalars['String']>;
  sub_theme?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
};

export type TrainingForRegister = {
  __typename?: 'TrainingForRegister';
  duration?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<TrainingForRegisterGroup>>>;
  id?: Maybe<Scalars['ID']>;
  info_url?: Maybe<Scalars['String']>;
  min_price?: Maybe<Scalars['String']>;
  next_start_date?: Maybe<Scalars['String']>;
  sub_theme?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
};

export type TrainingForRegisterGroup = {
  __typename?: 'TrainingForRegisterGroup';
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TrainingLection = {
  __typename?: 'TrainingLection';
  audio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  module_id?: Maybe<Scalars['String']>;
  module_params?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  theme?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type TrainingProgress = {
  __typename?: 'TrainingProgress';
  cup_best_answer?: Maybe<Scalars['Int']>;
  cup_best_comment?: Maybe<Scalars['Int']>;
  cup_best_phrase?: Maybe<Scalars['Int']>;
  current_lection_id?: Maybe<Scalars['ID']>;
  lection?: Maybe<Scalars['Int']>;
  lection_total?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Notifications>;
  phone?: Maybe<Scalars['String']>;
  phone_verified?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
  trainings?: Maybe<Array<Maybe<UserTrainings>>>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  diploma?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  price?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
  training_id?: Maybe<Scalars['ID']>;
};

export type UserPages = {
  __typename?: 'UserPages';
  results?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UserTrainings = {
  __typename?: 'UserTrainings';
  id?: Maybe<Scalars['ID']>;
  payment_status?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
};

export type UsersComments = {
  __typename?: 'UsersComments';
  comment?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type UsersEvaluations = {
  __typename?: 'UsersEvaluations';
  advantages?: Maybe<Scalars['String']>;
  disadvantages?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
  & { notifications?: Maybe<(
    { __typename?: 'Notifications' }
    & Pick<Notifications, 'email'>
  )>, trainings?: Maybe<Array<Maybe<(
    { __typename?: 'UserTrainings' }
    & Pick<UserTrainings, 'id' | 'theme'>
  )>>> }
);

export type DialogFieldsFragment = (
  { __typename?: 'DialogOnClient' }
  & Pick<DialogOnClient, 'id' | 'num' | 'date' | 'unviewedMessages' | 'amountAllMessages'>
  & { members?: Maybe<Array<Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'membersCode' | 'membersName'>
  )>>>, lastMessage?: Maybe<(
    { __typename?: 'LastMessage' }
    & Pick<LastMessage, 'author_id' | 'author_name' | 'date' | 'text'>
  )> }
);

export type UserLectionFieldsFragment = (
  { __typename?: 'Lection' }
  & Pick<Lection, 'id' | 'date' | 'order' | 'num' | 'theme' | 'audio' | 'video' | 'module_id' | 'module_params' | 'points_total'>
  & { tasks?: Maybe<Array<Maybe<(
    { __typename?: 'LectionTask' }
    & Pick<LectionTask, 'date_end' | 'date_start' | 'duration' | 'status' | 'num'>
    & { reward?: Maybe<(
      { __typename?: 'LectionTaskReward' }
      & Pick<LectionTaskReward, 'cup_best_comment' | 'cup_best_answer' | 'cup_best_phrase' | 'points' | 'points_activity'>
    )> }
  )>>> }
);

export type AdminUserGroupFieldsFragment = (
  { __typename?: 'UserGroup' }
  & Pick<UserGroup, 'id' | 'diploma'>
  & { payment?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'status' | 'amount' | 'price'>
  )> }
);

export type MessageFieldsFragment = (
  { __typename?: 'Message' }
  & Pick<Message, 'id' | 'author_id' | 'dialog_id' | 'author_name' | 'text' | 'date' | 'attachements'>
);

export type RegisterMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  pass: Scalars['String'];
  phone: Scalars['String'];
  subscribed: Scalars['Boolean'];
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['Int'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type ResendVerifyEmailCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendVerifyEmailCodeMutation = (
  { __typename?: 'Mutation' }
  & { resendVerifyEmailCode?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type VerifyPhoneMutationVariables = Exact<{
  phone: Scalars['String'];
  code: Scalars['Int'];
}>;


export type VerifyPhoneMutation = (
  { __typename?: 'Mutation' }
  & { verifyPhone?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type ResendVerifyPhoneCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendVerifyPhoneCodeMutation = (
  { __typename?: 'Mutation' }
  & { resendVerifyPhoneCode?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type RestorePasswordConfirmMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['Int'];
  newPass: Scalars['String'];
}>;


export type RestorePasswordConfirmMutation = (
  { __typename?: 'Mutation' }
  & { restorePasswordConfirm?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  notifications: NotificationsInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type UpdatePageMutationVariables = Exact<{
  name: Scalars['String'];
  html: Scalars['String'];
}>;


export type UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { updatePage?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  active: Scalars['Boolean'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  notifications: NotificationsInput;
  groups: Array<InputMaybe<Scalars['String']>>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type AdminUpdateUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  notifications: NotificationsInput;
  groups: Array<InputMaybe<Scalars['String']>>;
}>;


export type AdminUpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteMessageMutationVariables = Exact<{
  message_id: Scalars['ID'];
  attachements: Array<InputMaybe<Scalars['String']>>;
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteAttachementsMutationVariables = Exact<{
  attachementLinks: Array<InputMaybe<Scalars['String']>>;
  message_id?: InputMaybe<Scalars['ID']>;
}>;


export type DeleteAttachementsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAttachements'>
);

export type AddAttachementMutationVariables = Exact<{
  dialog_id: Scalars['String'];
  attachement: Scalars['String'];
  message_id?: InputMaybe<Scalars['ID']>;
}>;


export type AddAttachementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addAttachement'>
);

export type ViewAllMessagesInDialogMutationVariables = Exact<{
  dialog_id: Scalars['ID'];
}>;


export type ViewAllMessagesInDialogMutation = (
  { __typename?: 'Mutation' }
  & { viewAllMessagesInDialog?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type SendMessageMutationVariables = Exact<{
  dialog_id: Scalars['ID'];
  text: Scalars['String'];
  attachementsLinks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'author_id' | 'dialog_id' | 'author_name' | 'text' | 'date' | 'attachements'>
  )> }
);

export type UpdateMessageMutationVariables = Exact<{
  message_id: Scalars['ID'];
  text: Scalars['String'];
  attachementsToDelete: Array<InputMaybe<Scalars['String']>>;
}>;


export type UpdateMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'author_id' | 'dialog_id' | 'author_name' | 'text' | 'date' | 'attachements'>
  )> }
);

export type CreateDialogMutationVariables = Exact<{
  members: Array<InputMaybe<Scalars['String']>>;
}>;


export type CreateDialogMutation = (
  { __typename?: 'Mutation' }
  & { createDialog?: Maybe<(
    { __typename?: 'Dialog' }
    & Pick<Dialog, 'id' | 'num' | 'date'>
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'membersCode' | 'membersName'>
    )>>> }
  )> }
);

export type UpdateUserGroupPaymentMutationVariables = Exact<{
  groupId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  price?: InputMaybe<Scalars['Float']>;
}>;


export type UpdateUserGroupPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateUserGroupPayment?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'diploma'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status' | 'amount' | 'price'>
    )> }
  )> }
);

export type AddDiplomMutationVariables = Exact<{
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
  fileName: Scalars['String'];
}>;


export type AddDiplomMutation = (
  { __typename?: 'Mutation' }
  & { addDiplom?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'diploma'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status' | 'amount' | 'price'>
    )> }
  )> }
);

export type RemoveDiplomMutationVariables = Exact<{
  groupId: Scalars['ID'];
  filename: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type RemoveDiplomMutation = (
  { __typename?: 'Mutation' }
  & { removeDiplom?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'diploma'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status' | 'amount' | 'price'>
    )> }
  )> }
);

export type CreateTrainingMutationVariables = Exact<{
  theme: Scalars['String'];
  subTheme: Scalars['String'];
  shortTheme: Scalars['String'];
  infoUrl: Scalars['String'];
  programPage: Scalars['String'];
  materialPage: Scalars['String'];
}>;


export type CreateTrainingMutation = (
  { __typename?: 'Mutation' }
  & { createTraining?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'theme' | 'sub_theme' | 'short_theme' | 'info_url' | 'program_page' | 'material_page'>
  )> }
);

export type UpdateTrainingMutationVariables = Exact<{
  trainingId: Scalars['ID'];
  theme: Scalars['String'];
  subTheme: Scalars['String'];
  shortTheme: Scalars['String'];
  infoUrl: Scalars['String'];
  programPage: Scalars['String'];
  materialPage: Scalars['String'];
}>;


export type UpdateTrainingMutation = (
  { __typename?: 'Mutation' }
  & { updateTraining?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'theme' | 'sub_theme' | 'short_theme' | 'info_url' | 'program_page' | 'material_page'>
  )> }
);

export type CreateLectionMutationVariables = Exact<{
  trainingId: Scalars['ID'];
  num: Scalars['Int'];
  theme: Scalars['String'];
  audio: Scalars['String'];
  video: Scalars['String'];
  moduleId: Scalars['String'];
  moduleParams: Scalars['String'];
}>;


export type CreateLectionMutation = (
  { __typename?: 'Mutation' }
  & { createLection?: Maybe<(
    { __typename?: 'TrainingLection' }
    & Pick<TrainingLection, 'id' | 'order' | 'num' | 'theme' | 'audio' | 'video' | 'module_id' | 'module_params'>
  )> }
);

export type UpdateLectionMutationVariables = Exact<{
  trainingId: Scalars['ID'];
  lectionId: Scalars['ID'];
  num: Scalars['Int'];
  theme: Scalars['String'];
  audio: Scalars['String'];
  video: Scalars['String'];
  moduleId: Scalars['String'];
  moduleParams: Scalars['String'];
}>;


export type UpdateLectionMutation = (
  { __typename?: 'Mutation' }
  & { updateLection?: Maybe<(
    { __typename?: 'TrainingLection' }
    & Pick<TrainingLection, 'id' | 'order' | 'theme' | 'num' | 'audio' | 'video' | 'module_id' | 'module_params'>
  )> }
);

export type DeleteLectionMutationVariables = Exact<{
  trainingId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type DeleteLectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteLection?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type MoveLectionMutationVariables = Exact<{
  trainingId: Scalars['ID'];
  lectionId: Scalars['ID'];
  direction?: InputMaybe<Scalars['String']>;
}>;


export type MoveLectionMutation = (
  { __typename?: 'Mutation' }
  & { moveLection?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type SaveAnswerMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  taskNum: Scalars['Int'];
  content: Scalars['String'];
}>;


export type SaveAnswerMutation = (
  { __typename?: 'Mutation' }
  & { saveAnswer?: Maybe<(
    { __typename?: 'SavedAnswer' }
    & Pick<SavedAnswer, 'id' | 'user_id' | 'lection_id' | 'group_id' | 'task_num' | 'content'>
  )> }
);

export type DeleteSavedAnswerMutationVariables = Exact<{
  answerId: Scalars['ID'];
}>;


export type DeleteSavedAnswerMutation = (
  { __typename?: 'Mutation' }
  & { deleteSavedAnswer?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type CreateGroupMutationVariables = Exact<{
  trainingId: Scalars['ID'];
  start_date: Scalars['String'];
  price: Scalars['Float'];
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'price' | 'start_date' | 'end_date' | 'users_count'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  trainingId: Scalars['ID'];
  start_date: Scalars['String'];
  price: Scalars['Float'];
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'price' | 'start_date' | 'end_date' | 'users_count'>
  )> }
);

export type SaveMediaTimeMutationVariables = Exact<{
  lection_id: Scalars['ID'];
  group_id: Scalars['ID'];
  time: Scalars['Int'];
}>;


export type SaveMediaTimeMutation = (
  { __typename?: 'Mutation' }
  & { saveMediaTime?: Maybe<(
    { __typename?: 'SavedMediaTime' }
    & Pick<SavedMediaTime, 'id' | 'group_id' | 'lection_id' | 'user_id' | 'time'>
  )> }
);

export type DeleteSavedMediaTimeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSavedMediaTimeMutation = (
  { __typename?: 'Mutation' }
  & { deleteSavedMediaTime?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type CreateEmptyResultsMutationVariables = Exact<{
  groupId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type CreateEmptyResultsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEmptyResults'>
);

export type EditUserLectionResultsMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
  taskNum: Scalars['Int'];
  results: TaskResultInput;
}>;


export type EditUserLectionResultsMutation = (
  { __typename?: 'Mutation' }
  & { editUserLectionResults?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type LoginQueryVariables = Exact<{
  email: Scalars['String'];
  pass: Scalars['String'];
}>;


export type LoginQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'login'>
);

export type LoginUserForAdminQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type LoginUserForAdminQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loginUserForAdmin'>
);

export type RestorePasswordQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type RestorePasswordQuery = (
  { __typename?: 'Query' }
  & { restorePassword?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type TrainingsForRegisterQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingsForRegisterQuery = (
  { __typename?: 'Query' }
  & { trainingsForRegister?: Maybe<Array<Maybe<(
    { __typename?: 'TrainingForRegister' }
    & Pick<TrainingForRegister, 'id' | 'theme' | 'sub_theme' | 'info_url' | 'next_start_date' | 'duration' | 'min_price'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingForRegisterGroup' }
      & Pick<TrainingForRegisterGroup, 'id' | 'name' | 'date'>
    )>>> }
  )>>> }
);

export type UserLectionsQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type UserLectionsQuery = (
  { __typename?: 'Query' }
  & { userLections?: Maybe<(
    { __typename?: 'LectionsPlus1' }
    & Pick<LectionsPlus1, 'next_lection_date'>
    & { lections?: Maybe<Array<Maybe<(
      { __typename?: 'Lection' }
      & Pick<Lection, 'id' | 'date' | 'order' | 'num' | 'theme' | 'audio' | 'video' | 'module_id' | 'module_params' | 'points_total'>
      & { tasks?: Maybe<Array<Maybe<(
        { __typename?: 'LectionTask' }
        & Pick<LectionTask, 'date_end' | 'date_start' | 'duration' | 'status' | 'num'>
        & { reward?: Maybe<(
          { __typename?: 'LectionTaskReward' }
          & Pick<LectionTaskReward, 'cup_best_comment' | 'cup_best_answer' | 'cup_best_phrase' | 'points' | 'points_activity'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type UserLectionQueryVariables = Exact<{
  groupId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type UserLectionQuery = (
  { __typename?: 'Query' }
  & { userLection?: Maybe<(
    { __typename?: 'Lection' }
    & Pick<Lection, 'id' | 'date' | 'order' | 'num' | 'theme' | 'audio' | 'video' | 'module_id' | 'module_params' | 'points_total'>
    & { tasks?: Maybe<Array<Maybe<(
      { __typename?: 'LectionTask' }
      & Pick<LectionTask, 'date_end' | 'date_start' | 'duration' | 'status' | 'num'>
      & { reward?: Maybe<(
        { __typename?: 'LectionTaskReward' }
        & Pick<LectionTaskReward, 'cup_best_comment' | 'cup_best_answer' | 'cup_best_phrase' | 'points' | 'points_activity'>
      )> }
    )>>> }
  )> }
);

export type NextPrevLectionIdsQueryVariables = Exact<{
  groupId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type NextPrevLectionIdsQuery = (
  { __typename?: 'Query' }
  & { nextPrevLectionIds?: Maybe<(
    { __typename?: 'NextPrevLectionIds' }
    & Pick<NextPrevLectionIds, 'next' | 'prev'>
  )> }
);

export type M2ResultQueryVariables = Exact<{
  groupId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type M2ResultQuery = (
  { __typename?: 'Query' }
  & { m2Result?: Maybe<Array<Maybe<(
    { __typename?: 'Module2Result' }
    & Pick<Module2Result, 'date' | 'task' | 'role' | 'points'>
  )>>> }
);

export type UserGroupQueryVariables = Exact<{
  userId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type UserGroupQuery = (
  { __typename?: 'Query' }
  & { userGroup?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'price' | 'start_date' | 'training_id' | 'diploma'>
    & { payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'status' | 'amount' | 'price'>
    )> }
  )> }
);

export type RegisterForTrainingMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type RegisterForTrainingMutation = (
  { __typename?: 'Mutation' }
  & { registerForTraining?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type ProgressQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type ProgressQuery = (
  { __typename?: 'Query' }
  & { progress?: Maybe<(
    { __typename?: 'TrainingProgress' }
    & Pick<TrainingProgress, 'lection' | 'lection_total' | 'points' | 'cup_best_answer' | 'cup_best_comment' | 'cup_best_phrase' | 'current_lection_id'>
  )> }
);

export type PageQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type PageQuery = (
  { __typename?: 'Query' }
  & { page?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'name' | 'html'>
  )> }
);

export type PagesQueryVariables = Exact<{ [key: string]: never; }>;


export type PagesQuery = (
  { __typename?: 'Query' }
  & { pages?: Maybe<Array<Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id' | 'name' | 'html'>
  )>>> }
);

export type UserStatisticQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type UserStatisticQuery = (
  { __typename?: 'Query' }
  & { userStatistic?: Maybe<(
    { __typename?: 'Statistic' }
    & Pick<Statistic, 'topTen' | 'myPoints' | 'rankPlace'>
    & { points?: Maybe<Array<Maybe<(
      { __typename?: 'Point' }
      & Pick<Point, 'diploma' | 'name' | 'avgMy' | 'avg' | 'avgTen' | 'diplomaGold'>
    )>>>, bestAnswer?: Maybe<Array<Maybe<(
      { __typename?: 'CupBest' }
      & Pick<CupBest, 'lection_id' | 'lection_num'>
    )>>>, bestComment?: Maybe<Array<Maybe<(
      { __typename?: 'CupBest' }
      & Pick<CupBest, 'lection_id' | 'lection_num'>
    )>>>, bestPhrase?: Maybe<Array<Maybe<(
      { __typename?: 'CupBest' }
      & Pick<CupBest, 'lection_id' | 'lection_num'>
    )>>> }
  )> }
);

export type TrainingPageQueryVariables = Exact<{
  groupId: Scalars['ID'];
  pageId: Scalars['String'];
}>;


export type TrainingPageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'trainingPage'>
);

export type UserDiplomsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDiplomsQuery = (
  { __typename?: 'Query' }
  & { userDiploms?: Maybe<Array<Maybe<(
    { __typename?: 'Diplom' }
    & Pick<Diplom, 'diploma' | 'name'>
  )>>> }
);

export type UsersQueryVariables = Exact<{
  userName?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserPages' }
    & Pick<UserPages, 'total'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
      & { notifications?: Maybe<(
        { __typename?: 'Notifications' }
        & Pick<Notifications, 'email'>
      )>, trainings?: Maybe<Array<Maybe<(
        { __typename?: 'UserTrainings' }
        & Pick<UserTrainings, 'id' | 'theme'>
      )>>> }
    )>>> }
  )> }
);

export type UsersForDialogsQueryVariables = Exact<{
  userName?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
}>;


export type UsersForDialogsQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserPages' }
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>>> }
  )> }
);

export type DialogsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
}>;


export type DialogsQuery = (
  { __typename?: 'Query' }
  & { dialogs?: Maybe<(
    { __typename?: 'DialogPages' }
    & Pick<DialogPages, 'total'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'DialogOnClient' }
      & Pick<DialogOnClient, 'id' | 'num' | 'date' | 'unviewedMessages' | 'amountAllMessages'>
      & { members?: Maybe<Array<Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'membersCode' | 'membersName'>
      )>>>, lastMessage?: Maybe<(
        { __typename?: 'LastMessage' }
        & Pick<LastMessage, 'author_id' | 'author_name' | 'date' | 'text'>
      )> }
    )>>> }
  )> }
);

export type DialogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DialogQuery = (
  { __typename?: 'Query' }
  & { dialog?: Maybe<(
    { __typename?: 'Dialog' }
    & Pick<Dialog, 'id' | 'num' | 'date'>
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'membersCode' | 'membersName'>
    )>>>, messages?: Maybe<Array<Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'author_id' | 'author_name' | 'dialog_id' | 'date' | 'text' | 'attachements'>
    )>>> }
  )> }
);

export type AllUnviewedMessegesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUnviewedMessegesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allUnviewedMesseges'>
);

export type GroupListQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupListQuery = (
  { __typename?: 'Query' }
  & { groupList?: Maybe<Array<Maybe<(
    { __typename?: 'GroupList' }
    & Pick<GroupList, 'id' | 'name'>
  )>>> }
);

export type GroupsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
}>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<(
    { __typename?: 'GroupPages' }
    & Pick<GroupPages, 'total'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'price' | 'start_date' | 'end_date' | 'users_count'>
    )>>> }
  )> }
);

export type GroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'price' | 'start_date' | 'end_date' | 'users_count'>
  )> }
);

export type GroupEndDateQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupEndDateQuery = (
  { __typename?: 'Query' }
  & { groupEndDate?: Maybe<(
    { __typename?: 'GroupEndDate' }
    & Pick<GroupEndDate, 'id' | 'end_date'>
  )> }
);

export type AdminUserGroupsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AdminUserGroupsQuery = (
  { __typename?: 'Query' }
  & { adminUserGroups?: Maybe<Array<Maybe<(
    { __typename?: 'AdminUserGroup' }
    & Pick<AdminUserGroup, 'id' | 'name'>
  )>>> }
);

export type UserInfoNameQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserInfoNameQuery = (
  { __typename?: 'Query' }
  & { userInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name' | 'id'>
  )> }
);

export type UserInfoQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserInfoQuery = (
  { __typename?: 'Query' }
  & { userInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'role' | 'name' | 'phone' | 'email' | 'email_verified' | 'phone_verified' | 'subscribed' | 'created_at'>
    & { notifications?: Maybe<(
      { __typename?: 'Notifications' }
      & Pick<Notifications, 'email'>
    )>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'UserTrainings' }
      & Pick<UserTrainings, 'id' | 'theme'>
    )>>> }
  )> }
);

export type TrainingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingsQuery = (
  { __typename?: 'Query' }
  & { trainings?: Maybe<Array<Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'theme' | 'sub_theme' | 'short_theme' | 'program_page' | 'material_page' | 'lection_count' | 'group_ended' | 'group_current' | 'group_coming'>
  )>>> }
);

export type TrainingQueryVariables = Exact<{
  trainingId: Scalars['ID'];
}>;


export type TrainingQuery = (
  { __typename?: 'Query' }
  & { training?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'theme' | 'sub_theme' | 'short_theme' | 'info_url' | 'program_page' | 'material_page'>
  )> }
);

export type LectionsQueryVariables = Exact<{
  trainingId: Scalars['ID'];
}>;


export type LectionsQuery = (
  { __typename?: 'Query' }
  & { lections?: Maybe<Array<Maybe<(
    { __typename?: 'TrainingLection' }
    & Pick<TrainingLection, 'id' | 'order' | 'num' | 'theme' | 'audio' | 'video' | 'module_id' | 'module_params'>
  )>>> }
);

export type SavedAnswerQueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  taskNum: Scalars['Int'];
}>;


export type SavedAnswerQuery = (
  { __typename?: 'Query' }
  & { savedAnswer?: Maybe<(
    { __typename?: 'SavedAnswer' }
    & Pick<SavedAnswer, 'id' | 'user_id' | 'lection_id' | 'group_id' | 'task_num' | 'content' | 'updatedAt' | 'createdAt'>
  )> }
);

export type SavedMediaTimeQueryVariables = Exact<{
  lection_id: Scalars['ID'];
  group_id: Scalars['ID'];
}>;


export type SavedMediaTimeQuery = (
  { __typename?: 'Query' }
  & { savedMediaTime?: Maybe<(
    { __typename?: 'SavedMediaTime' }
    & Pick<SavedMediaTime, 'id' | 'group_id' | 'lection_id' | 'user_id' | 'time'>
  )> }
);

export type GroupStatisticsQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupStatisticsQuery = (
  { __typename?: 'Query' }
  & { groupStatistics?: Maybe<(
    { __typename?: 'GroupStatistics' }
    & { lectionsStatistics?: Maybe<Array<Maybe<(
      { __typename?: 'LectionStatistics' }
      & Pick<LectionStatistics, 'lectionId' | 'num' | 'minResult' | 'averageResult' | 'maxResult' | 'step1' | 'step2' | 'step3' | 'step4' | 'allSteps'>
    )>>>, usersRating?: Maybe<Array<Maybe<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'name' | 'email' | 'points' | 'position'>
    )>>> }
  )> }
);

export type GroupLectionsListQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupLectionsListQuery = (
  { __typename?: 'Query' }
  & { groupLectionsList?: Maybe<(
    { __typename?: 'GroupLections' }
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'start_date' | 'end_date' | 'users_count'>
    )>, lections?: Maybe<Array<Maybe<(
      { __typename?: 'GroupLection' }
      & Pick<GroupLection, 'id' | 'module_id' | 'num' | 'status' | 'theme' | 'usersWithAccess' | 'usersWithResults'>
    )>>> }
  )> }
);

export type AdminUserLectionResultsQueryVariables = Exact<{
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type AdminUserLectionResultsQuery = (
  { __typename?: 'Query' }
  & { adminUserLectionResults?: Maybe<Array<Maybe<(
    { __typename?: 'AdminUserLectionResults' }
    & Pick<AdminUserLectionResults, 'lection_id' | 'module_id' | 'num' | 'theme'>
    & { lection_result?: Maybe<Array<Maybe<(
      { __typename?: 'LectionTaskResults' }
      & Pick<LectionTaskResults, 'num' | 'points' | 'points_activity' | 'cup_best_answer' | 'cup_best_comment' | 'cup_best_phrase'>
    )>>> }
  )>>> }
);

export type CountryFlagCodeQueryVariables = Exact<{
  userIp: Scalars['String'];
}>;


export type CountryFlagCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countryFlagCode'>
);

export type MessageToSchoolQueryVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  training: Scalars['String'];
  date: Scalars['String'];
  amountToPay: Scalars['String'];
  message_requisites?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
}>;


export type MessageToSchoolQuery = (
  { __typename?: 'Query' }
  & { messageToSchool?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type AddM1Task1MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  answer: Scalars['String'];
  answer2: Scalars['String'];
}>;


export type AddM1Task1Mutation = (
  { __typename?: 'Mutation' }
  & { addM1Task1?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type AddM1Task2MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  comments: Array<InputMaybe<Task2Comment>>;
}>;


export type AddM1Task2Mutation = (
  { __typename?: 'Mutation' }
  & { addM1Task2?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type AddM1Task3MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  evaluation: Task3EvaluationInput;
}>;


export type AddM1Task3Mutation = (
  { __typename?: 'Mutation' }
  & { addM1Task3?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type AddM1Task4MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  evaluationComments: Array<InputMaybe<Task4EvaluationCommentInput>>;
}>;


export type AddM1Task4Mutation = (
  { __typename?: 'Mutation' }
  & { addM1Task4?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type M1Task1QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M1Task1Query = (
  { __typename?: 'Query' }
  & { m1Task1?: Maybe<(
    { __typename?: 'Task1' }
    & Pick<Task1, 'question' | 'answer' | 'answer2'>
  )> }
);

export type M1Task2QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M1Task2Query = (
  { __typename?: 'Query' }
  & { m1Task2?: Maybe<Array<Maybe<(
    { __typename?: 'Task2' }
    & Pick<Task2, 'user_id' | 'answer' | 'answer2' | 'my_comment' | 'my_points'>
  )>>> }
);

export type M1Task3QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M1Task3Query = (
  { __typename?: 'Query' }
  & { m1Task3?: Maybe<(
    { __typename?: 'Task3' }
    & Pick<Task3, 'user_id' | 'answer' | 'answer2'>
    & { evaluation?: Maybe<(
      { __typename?: 'Task3Evaluation' }
      & Pick<Task3Evaluation, 'advantages' | 'disadvantages'>
    )> }
  )> }
);

export type M1Task4QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M1Task4Query = (
  { __typename?: 'Query' }
  & { m1Task4?: Maybe<(
    { __typename?: 'Task4' }
    & Pick<Task4, 'answer' | 'answer2' | 'groupUncompleteTask'>
    & { evaluation_comments?: Maybe<Array<Maybe<(
      { __typename?: 'Task4EvaluationComment' }
      & Pick<Task4EvaluationComment, 'user_id' | 'advantages' | 'disadvantages' | 'comment' | 'points'>
    )>>> }
  )> }
);

export type M1ResultsQueryVariables = Exact<{
  groupId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type M1ResultsQuery = (
  { __typename?: 'Query' }
  & { m1Result?: Maybe<(
    { __typename?: 'LectionResult' }
    & Pick<LectionResult, 'answer' | 'answer2' | 'points' | 'cup_best_answer' | 'points_evaluation' | 'cup_best_comment' | 'points_activity'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'UsersComments' }
      & Pick<UsersComments, 'user_id' | 'comment' | 'points'>
    )>>>, evaluations?: Maybe<Array<Maybe<(
      { __typename?: 'UsersEvaluations' }
      & Pick<UsersEvaluations, 'user_id' | 'advantages' | 'disadvantages'>
    )>>>, evaluation_comments?: Maybe<(
      { __typename?: 'Evaluation' }
      & Pick<Evaluation, 'answer' | 'answer2'>
      & { myEvaluation?: Maybe<(
        { __typename?: 'MyEvaluation' }
        & Pick<MyEvaluation, 'advantages' | 'disadvantages'>
      )>, evaluation_comments?: Maybe<Array<Maybe<(
        { __typename?: 'EvaluationComments' }
        & Pick<EvaluationComments, 'user_id' | 'comment' | 'points'>
      )>>> }
    )> }
  )> }
);

export type UserStepFieldsFragment = (
  { __typename?: 'M3TaskResult' }
  & Pick<M3TaskResult, 'answer' | 'points'>
  & { characteristics?: Maybe<Array<Maybe<(
    { __typename?: 'Characteristics' }
    & Pick<Characteristics, 'best' | 'worst' | 'advantages' | 'disadvantages'>
  )>>> }
);

export type AddM3TasksMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  taskNum: Scalars['Int'];
  answer: Scalars['String'];
}>;


export type AddM3TasksMutation = (
  { __typename?: 'Mutation' }
  & { addM3Tasks?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type AddM3Task4MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  results: Array<InputMaybe<M3Task4Input>>;
}>;


export type AddM3Task4Mutation = (
  { __typename?: 'Mutation' }
  & { addM3Task4?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type M3Task1QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M3Task1Query = (
  { __typename?: 'Query' }
  & { m3Task1?: Maybe<(
    { __typename?: 'M3Task1' }
    & Pick<M3Task1, 'step1Answer'>
  )> }
);

export type M3Task2QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M3Task2Query = (
  { __typename?: 'Query' }
  & { m3Task2?: Maybe<(
    { __typename?: 'M3Task2' }
    & Pick<M3Task2, 'step1Answer' | 'step2Answer'>
  )> }
);

export type M3Task3QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M3Task3Query = (
  { __typename?: 'Query' }
  & { m3Task3?: Maybe<(
    { __typename?: 'M3Task3' }
    & Pick<M3Task3, 'step1Answer' | 'step2Answer' | 'step3Answer'>
  )> }
);

export type M3Task4QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M3Task4Query = (
  { __typename?: 'Query' }
  & { m3Task4?: Maybe<Array<Maybe<(
    { __typename?: 'M3Task4' }
    & Pick<M3Task4, 'user_id' | 'answer' | 'step' | 'advantages' | 'disadvantages' | 'best' | 'worst'>
  )>>> }
);

export type M3ResultQueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M3ResultQuery = (
  { __typename?: 'Query' }
  & { m3Result?: Maybe<(
    { __typename?: 'M3LectionResult' }
    & Pick<M3LectionResult, 'points' | 'points_activity' | 'cup_best_phrase'>
    & { dialog1?: Maybe<(
      { __typename?: 'Dialog1' }
      & Pick<Dialog1, 'nextUserStep2'>
      & { userStep1?: Maybe<(
        { __typename?: 'M3TaskResult' }
        & Pick<M3TaskResult, 'answer' | 'points'>
        & { characteristics?: Maybe<Array<Maybe<(
          { __typename?: 'Characteristics' }
          & Pick<Characteristics, 'best' | 'worst' | 'advantages' | 'disadvantages'>
        )>>> }
      )>, userStep3?: Maybe<(
        { __typename?: 'M3TaskResult' }
        & Pick<M3TaskResult, 'answer' | 'points'>
        & { characteristics?: Maybe<Array<Maybe<(
          { __typename?: 'Characteristics' }
          & Pick<Characteristics, 'best' | 'worst' | 'advantages' | 'disadvantages'>
        )>>> }
      )> }
    )>, dialog2?: Maybe<(
      { __typename?: 'Dialog2' }
      & Pick<Dialog2, 'prevUserStep1' | 'prevUserStep3'>
      & { userStep2?: Maybe<(
        { __typename?: 'M3TaskResult' }
        & Pick<M3TaskResult, 'answer' | 'points'>
        & { characteristics?: Maybe<Array<Maybe<(
          { __typename?: 'Characteristics' }
          & Pick<Characteristics, 'best' | 'worst' | 'advantages' | 'disadvantages'>
        )>>> }
      )> }
    )> }
  )> }
);

export type AddM4Task1MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  answer: Scalars['String'];
}>;


export type AddM4Task1Mutation = (
  { __typename?: 'Mutation' }
  & { addM4Task1?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddM4Task2MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  results: Array<InputMaybe<M4Task2Input>>;
}>;


export type AddM4Task2Mutation = (
  { __typename?: 'Mutation' }
  & { addM4Task2?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type ViewM4MessageMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  messageId: Scalars['ID'];
}>;


export type ViewM4MessageMutation = (
  { __typename?: 'Mutation' }
  & { viewM4Message?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type SendM4MessageMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  text: Scalars['String'];
  isProposed: Scalars['Boolean'];
}>;


export type SendM4MessageMutation = (
  { __typename?: 'Mutation' }
  & { sendM4Message?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type VoteM4MessageMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  messageId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type VoteM4MessageMutation = (
  { __typename?: 'Mutation' }
  & { voteM4Message?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddM4Task4MutationVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  characteristics: Array<InputMaybe<M4Task2Input>>;
  groupAnswers: Array<InputMaybe<M4Task4Input>>;
}>;


export type AddM4Task4Mutation = (
  { __typename?: 'Mutation' }
  & { addM4Task4?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type M4Task1QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M4Task1Query = (
  { __typename?: 'Query' }
  & { m4Task1?: Maybe<(
    { __typename?: 'M4Task1' }
    & Pick<M4Task1, 'answer'>
  )> }
);

export type M4Task2QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M4Task2Query = (
  { __typename?: 'Query' }
  & { m4Task2?: Maybe<Array<Maybe<(
    { __typename?: 'M4Task2' }
    & Pick<M4Task2, 'user_id' | 'answer' | 'advantages' | 'disadvantages' | 'points'>
  )>>> }
);

export type M4Task3QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
  lastViewed?: InputMaybe<Scalars['ID']>;
}>;


export type M4Task3Query = (
  { __typename?: 'Query' }
  & { m4Task3?: Maybe<(
    { __typename?: 'M4Task3' }
    & Pick<M4Task3, 'last_viewed' | 'max_votes' | 'count_unviewed' | 'is_chat_closed' | 'userColor'>
    & { messages?: Maybe<Array<Maybe<(
      { __typename?: 'M4Message' }
      & Pick<M4Message, 'id' | 'user_id' | 'color' | 'text' | 'is_proposed' | 'is_voted' | 'is_group_answer' | 'votes' | 'date'>
    )>>> }
  )> }
);

export type M4Task4QueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M4Task4Query = (
  { __typename?: 'Query' }
  & { m4Task4?: Maybe<(
    { __typename?: 'M4Task4' }
    & { group_answers?: Maybe<Array<Maybe<(
      { __typename?: 'GroupAnswer' }
      & Pick<GroupAnswer, 'user_id' | 'answer' | 'points'>
    )>>>, characteristics?: Maybe<Array<Maybe<(
      { __typename?: 'M4Task2' }
      & Pick<M4Task2, 'user_id' | 'color' | 'advantages' | 'disadvantages' | 'points'>
    )>>> }
  )> }
);

export type M4ResultQueryVariables = Exact<{
  lectionId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type M4ResultQuery = (
  { __typename?: 'Query' }
  & { m4Result?: Maybe<(
    { __typename?: 'M4Result' }
    & Pick<M4Result, 'points' | 'points_activity' | 'answer' | 'answer_points' | 'group_answer' | 'group_answer_points' | 'team_work_points'>
    & { answer_characteristics?: Maybe<Array<Maybe<(
      { __typename?: 'M4Task2' }
      & Pick<M4Task2, 'advantages' | 'disadvantages' | 'points'>
    )>>>, team_work_characteristics?: Maybe<Array<Maybe<(
      { __typename?: 'M4Task2' }
      & Pick<M4Task2, 'advantages' | 'disadvantages' | 'points'>
    )>>> }
  )> }
);

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  active
  role
  name
  phone
  email
  email_verified
  phone_verified
  notifications {
    email
  }
  subscribed
  trainings {
    id
    theme
  }
  created_at
}
    `;
export const DialogFieldsFragmentDoc = gql`
    fragment DialogFields on DialogOnClient {
  id
  members {
    membersCode
    membersName
  }
  lastMessage {
    author_id
    author_name
    date
    text
  }
  num
  date
  unviewedMessages
  amountAllMessages
}
    `;
export const UserLectionFieldsFragmentDoc = gql`
    fragment UserLectionFields on Lection {
  id
  date
  order
  num
  theme
  audio
  video
  module_id
  module_params
  points_total
  tasks {
    date_end
    date_start
    reward {
      cup_best_comment
      cup_best_answer
      cup_best_phrase
      points
      points_activity
    }
    duration
    status
    num
  }
}
    `;
export const AdminUserGroupFieldsFragmentDoc = gql`
    fragment AdminUserGroupFields on UserGroup {
  id
  payment {
    status
    amount
    price
  }
  diploma
}
    `;
export const MessageFieldsFragmentDoc = gql`
    fragment MessageFields on Message {
  id
  author_id
  dialog_id
  author_name
  text
  date
  attachements
}
    `;
export const UserStepFieldsFragmentDoc = gql`
    fragment userStepFields on M3TaskResult {
  answer
  points
  characteristics {
    best
    worst
    advantages
    disadvantages
  }
}
    `;
export const RegisterDocument = gql`
    mutation Register($name: String!, $email: String!, $pass: String!, $phone: String!, $subscribed: Boolean!) {
  register(
    name: $name
    email: $email
    pass: $pass
    phone: $phone
    subscribed: $subscribed
  )
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      pass: // value for 'pass'
 *      phone: // value for 'phone'
 *      subscribed: // value for 'subscribed'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($email: String!, $code: Int!) {
  verifyEmail(email: $email, code: $code) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ResendVerifyEmailCodeDocument = gql`
    mutation ResendVerifyEmailCode {
  resendVerifyEmailCode {
    id
    message
  }
}
    `;
export type ResendVerifyEmailCodeMutationFn = Apollo.MutationFunction<ResendVerifyEmailCodeMutation, ResendVerifyEmailCodeMutationVariables>;

/**
 * __useResendVerifyEmailCodeMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailCodeMutation, { data, loading, error }] = useResendVerifyEmailCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerifyEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerifyEmailCodeMutation, ResendVerifyEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerifyEmailCodeMutation, ResendVerifyEmailCodeMutationVariables>(ResendVerifyEmailCodeDocument, options);
      }
export type ResendVerifyEmailCodeMutationHookResult = ReturnType<typeof useResendVerifyEmailCodeMutation>;
export type ResendVerifyEmailCodeMutationResult = Apollo.MutationResult<ResendVerifyEmailCodeMutation>;
export type ResendVerifyEmailCodeMutationOptions = Apollo.BaseMutationOptions<ResendVerifyEmailCodeMutation, ResendVerifyEmailCodeMutationVariables>;
export const VerifyPhoneDocument = gql`
    mutation VerifyPhone($phone: String!, $code: Int!) {
  verifyPhone(phone: $phone, code: $code) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type VerifyPhoneMutationFn = Apollo.MutationFunction<VerifyPhoneMutation, VerifyPhoneMutationVariables>;

/**
 * __useVerifyPhoneMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneMutation, { data, loading, error }] = useVerifyPhoneMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyPhoneMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPhoneMutation, VerifyPhoneMutationVariables>(VerifyPhoneDocument, options);
      }
export type VerifyPhoneMutationHookResult = ReturnType<typeof useVerifyPhoneMutation>;
export type VerifyPhoneMutationResult = Apollo.MutationResult<VerifyPhoneMutation>;
export type VerifyPhoneMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>;
export const ResendVerifyPhoneCodeDocument = gql`
    mutation ResendVerifyPhoneCode {
  resendVerifyPhoneCode {
    id
    message
  }
}
    `;
export type ResendVerifyPhoneCodeMutationFn = Apollo.MutationFunction<ResendVerifyPhoneCodeMutation, ResendVerifyPhoneCodeMutationVariables>;

/**
 * __useResendVerifyPhoneCodeMutation__
 *
 * To run a mutation, you first call `useResendVerifyPhoneCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyPhoneCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyPhoneCodeMutation, { data, loading, error }] = useResendVerifyPhoneCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerifyPhoneCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerifyPhoneCodeMutation, ResendVerifyPhoneCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerifyPhoneCodeMutation, ResendVerifyPhoneCodeMutationVariables>(ResendVerifyPhoneCodeDocument, options);
      }
export type ResendVerifyPhoneCodeMutationHookResult = ReturnType<typeof useResendVerifyPhoneCodeMutation>;
export type ResendVerifyPhoneCodeMutationResult = Apollo.MutationResult<ResendVerifyPhoneCodeMutation>;
export type ResendVerifyPhoneCodeMutationOptions = Apollo.BaseMutationOptions<ResendVerifyPhoneCodeMutation, ResendVerifyPhoneCodeMutationVariables>;
export const RestorePasswordConfirmDocument = gql`
    mutation RestorePasswordConfirm($email: String!, $code: Int!, $newPass: String!) {
  restorePasswordConfirm(email: $email, code: $code, newPass: $newPass) {
    id
    message
  }
}
    `;
export type RestorePasswordConfirmMutationFn = Apollo.MutationFunction<RestorePasswordConfirmMutation, RestorePasswordConfirmMutationVariables>;

/**
 * __useRestorePasswordConfirmMutation__
 *
 * To run a mutation, you first call `useRestorePasswordConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePasswordConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePasswordConfirmMutation, { data, loading, error }] = useRestorePasswordConfirmMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      newPass: // value for 'newPass'
 *   },
 * });
 */
export function useRestorePasswordConfirmMutation(baseOptions?: Apollo.MutationHookOptions<RestorePasswordConfirmMutation, RestorePasswordConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestorePasswordConfirmMutation, RestorePasswordConfirmMutationVariables>(RestorePasswordConfirmDocument, options);
      }
export type RestorePasswordConfirmMutationHookResult = ReturnType<typeof useRestorePasswordConfirmMutation>;
export type RestorePasswordConfirmMutationResult = Apollo.MutationResult<RestorePasswordConfirmMutation>;
export type RestorePasswordConfirmMutationOptions = Apollo.BaseMutationOptions<RestorePasswordConfirmMutation, RestorePasswordConfirmMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($name: String!, $email: String!, $phone: String!, $notifications: NotificationsInput!) {
  updateUser(
    name: $name
    email: $email
    phone: $phone
    notifications: $notifications
  ) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      notifications: // value for 'notifications'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdatePageDocument = gql`
    mutation UpdatePage($name: String!, $html: String!) {
  updatePage(name: $name, html: $html) {
    id
    message
  }
}
    `;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      html: // value for 'html'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($active: Boolean!, $name: String!, $email: String!, $phone: String!, $notifications: NotificationsInput!, $groups: [String]!) {
  createUser(
    active: $active
    name: $name
    email: $email
    phone: $phone
    notifications: $notifications
    groups: $groups
  ) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      notifications: // value for 'notifications'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const AdminUpdateUserDocument = gql`
    mutation AdminUpdateUser($userId: ID!, $active: Boolean!, $name: String!, $email: String!, $phone: String!, $notifications: NotificationsInput!, $groups: [String]!) {
  adminUpdateUser(
    userId: $userId
    active: $active
    name: $name
    email: $email
    phone: $phone
    notifications: $notifications
    groups: $groups
  ) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      notifications: // value for 'notifications'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(AdminUpdateUserDocument, options);
      }
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: ID!) {
  deleteUser(userId: $userId) {
    id
    message
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation DeleteMessage($message_id: ID!, $attachements: [String]!) {
  deleteMessage(message_id: $message_id, attachements: $attachements) {
    id
    message
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      message_id: // value for 'message_id'
 *      attachements: // value for 'attachements'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const DeleteAttachementsDocument = gql`
    mutation DeleteAttachements($attachementLinks: [String]!, $message_id: ID) {
  deleteAttachements(attachementLinks: $attachementLinks, message_id: $message_id)
}
    `;
export type DeleteAttachementsMutationFn = Apollo.MutationFunction<DeleteAttachementsMutation, DeleteAttachementsMutationVariables>;

/**
 * __useDeleteAttachementsMutation__
 *
 * To run a mutation, you first call `useDeleteAttachementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachementsMutation, { data, loading, error }] = useDeleteAttachementsMutation({
 *   variables: {
 *      attachementLinks: // value for 'attachementLinks'
 *      message_id: // value for 'message_id'
 *   },
 * });
 */
export function useDeleteAttachementsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachementsMutation, DeleteAttachementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachementsMutation, DeleteAttachementsMutationVariables>(DeleteAttachementsDocument, options);
      }
export type DeleteAttachementsMutationHookResult = ReturnType<typeof useDeleteAttachementsMutation>;
export type DeleteAttachementsMutationResult = Apollo.MutationResult<DeleteAttachementsMutation>;
export type DeleteAttachementsMutationOptions = Apollo.BaseMutationOptions<DeleteAttachementsMutation, DeleteAttachementsMutationVariables>;
export const AddAttachementDocument = gql`
    mutation AddAttachement($dialog_id: String!, $attachement: String!, $message_id: ID) {
  addAttachement(
    dialog_id: $dialog_id
    attachement: $attachement
    message_id: $message_id
  )
}
    `;
export type AddAttachementMutationFn = Apollo.MutationFunction<AddAttachementMutation, AddAttachementMutationVariables>;

/**
 * __useAddAttachementMutation__
 *
 * To run a mutation, you first call `useAddAttachementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachementMutation, { data, loading, error }] = useAddAttachementMutation({
 *   variables: {
 *      dialog_id: // value for 'dialog_id'
 *      attachement: // value for 'attachement'
 *      message_id: // value for 'message_id'
 *   },
 * });
 */
export function useAddAttachementMutation(baseOptions?: Apollo.MutationHookOptions<AddAttachementMutation, AddAttachementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttachementMutation, AddAttachementMutationVariables>(AddAttachementDocument, options);
      }
export type AddAttachementMutationHookResult = ReturnType<typeof useAddAttachementMutation>;
export type AddAttachementMutationResult = Apollo.MutationResult<AddAttachementMutation>;
export type AddAttachementMutationOptions = Apollo.BaseMutationOptions<AddAttachementMutation, AddAttachementMutationVariables>;
export const ViewAllMessagesInDialogDocument = gql`
    mutation ViewAllMessagesInDialog($dialog_id: ID!) {
  viewAllMessagesInDialog(dialog_id: $dialog_id) {
    id
    message
  }
}
    `;
export type ViewAllMessagesInDialogMutationFn = Apollo.MutationFunction<ViewAllMessagesInDialogMutation, ViewAllMessagesInDialogMutationVariables>;

/**
 * __useViewAllMessagesInDialogMutation__
 *
 * To run a mutation, you first call `useViewAllMessagesInDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllMessagesInDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllMessagesInDialogMutation, { data, loading, error }] = useViewAllMessagesInDialogMutation({
 *   variables: {
 *      dialog_id: // value for 'dialog_id'
 *   },
 * });
 */
export function useViewAllMessagesInDialogMutation(baseOptions?: Apollo.MutationHookOptions<ViewAllMessagesInDialogMutation, ViewAllMessagesInDialogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewAllMessagesInDialogMutation, ViewAllMessagesInDialogMutationVariables>(ViewAllMessagesInDialogDocument, options);
      }
export type ViewAllMessagesInDialogMutationHookResult = ReturnType<typeof useViewAllMessagesInDialogMutation>;
export type ViewAllMessagesInDialogMutationResult = Apollo.MutationResult<ViewAllMessagesInDialogMutation>;
export type ViewAllMessagesInDialogMutationOptions = Apollo.BaseMutationOptions<ViewAllMessagesInDialogMutation, ViewAllMessagesInDialogMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($dialog_id: ID!, $text: String!, $attachementsLinks: [String]) {
  sendMessage(
    dialog_id: $dialog_id
    text: $text
    attachementsLinks: $attachementsLinks
  ) {
    ...MessageFields
  }
}
    ${MessageFieldsFragmentDoc}`;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      dialog_id: // value for 'dialog_id'
 *      text: // value for 'text'
 *      attachementsLinks: // value for 'attachementsLinks'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const UpdateMessageDocument = gql`
    mutation UpdateMessage($message_id: ID!, $text: String!, $attachementsToDelete: [String]!) {
  updateMessage(
    message_id: $message_id
    text: $text
    attachementsToDelete: $attachementsToDelete
  ) {
    ...MessageFields
  }
}
    ${MessageFieldsFragmentDoc}`;
export type UpdateMessageMutationFn = Apollo.MutationFunction<UpdateMessageMutation, UpdateMessageMutationVariables>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      message_id: // value for 'message_id'
 *      text: // value for 'text'
 *      attachementsToDelete: // value for 'attachementsToDelete'
 *   },
 * });
 */
export function useUpdateMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageMutation, UpdateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageMutation, UpdateMessageMutationVariables>(UpdateMessageDocument, options);
      }
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<UpdateMessageMutation, UpdateMessageMutationVariables>;
export const CreateDialogDocument = gql`
    mutation CreateDialog($members: [String]!) {
  createDialog(members: $members) {
    id
    members {
      membersCode
      membersName
    }
    num
    date
  }
}
    `;
export type CreateDialogMutationFn = Apollo.MutationFunction<CreateDialogMutation, CreateDialogMutationVariables>;

/**
 * __useCreateDialogMutation__
 *
 * To run a mutation, you first call `useCreateDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDialogMutation, { data, loading, error }] = useCreateDialogMutation({
 *   variables: {
 *      members: // value for 'members'
 *   },
 * });
 */
export function useCreateDialogMutation(baseOptions?: Apollo.MutationHookOptions<CreateDialogMutation, CreateDialogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDialogMutation, CreateDialogMutationVariables>(CreateDialogDocument, options);
      }
export type CreateDialogMutationHookResult = ReturnType<typeof useCreateDialogMutation>;
export type CreateDialogMutationResult = Apollo.MutationResult<CreateDialogMutation>;
export type CreateDialogMutationOptions = Apollo.BaseMutationOptions<CreateDialogMutation, CreateDialogMutationVariables>;
export const UpdateUserGroupPaymentDocument = gql`
    mutation UpdateUserGroupPayment($groupId: ID!, $amount: Float, $status: String, $userId: ID!, $price: Float) {
  updateUserGroupPayment(
    group_id: $groupId
    amount: $amount
    status: $status
    user_id: $userId
    price: $price
  ) {
    ...AdminUserGroupFields
  }
}
    ${AdminUserGroupFieldsFragmentDoc}`;
export type UpdateUserGroupPaymentMutationFn = Apollo.MutationFunction<UpdateUserGroupPaymentMutation, UpdateUserGroupPaymentMutationVariables>;

/**
 * __useUpdateUserGroupPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupPaymentMutation, { data, loading, error }] = useUpdateUserGroupPaymentMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      amount: // value for 'amount'
 *      status: // value for 'status'
 *      userId: // value for 'userId'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useUpdateUserGroupPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupPaymentMutation, UpdateUserGroupPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupPaymentMutation, UpdateUserGroupPaymentMutationVariables>(UpdateUserGroupPaymentDocument, options);
      }
export type UpdateUserGroupPaymentMutationHookResult = ReturnType<typeof useUpdateUserGroupPaymentMutation>;
export type UpdateUserGroupPaymentMutationResult = Apollo.MutationResult<UpdateUserGroupPaymentMutation>;
export type UpdateUserGroupPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupPaymentMutation, UpdateUserGroupPaymentMutationVariables>;
export const AddDiplomDocument = gql`
    mutation AddDiplom($groupId: ID!, $userId: ID!, $fileName: String!) {
  addDiplom(group_id: $groupId, user_id: $userId, fileName: $fileName) {
    ...AdminUserGroupFields
  }
}
    ${AdminUserGroupFieldsFragmentDoc}`;
export type AddDiplomMutationFn = Apollo.MutationFunction<AddDiplomMutation, AddDiplomMutationVariables>;

/**
 * __useAddDiplomMutation__
 *
 * To run a mutation, you first call `useAddDiplomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiplomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiplomMutation, { data, loading, error }] = useAddDiplomMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useAddDiplomMutation(baseOptions?: Apollo.MutationHookOptions<AddDiplomMutation, AddDiplomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiplomMutation, AddDiplomMutationVariables>(AddDiplomDocument, options);
      }
export type AddDiplomMutationHookResult = ReturnType<typeof useAddDiplomMutation>;
export type AddDiplomMutationResult = Apollo.MutationResult<AddDiplomMutation>;
export type AddDiplomMutationOptions = Apollo.BaseMutationOptions<AddDiplomMutation, AddDiplomMutationVariables>;
export const RemoveDiplomDocument = gql`
    mutation RemoveDiplom($groupId: ID!, $filename: String!, $userId: ID!) {
  removeDiplom(group_id: $groupId, filename: $filename, user_id: $userId) {
    ...AdminUserGroupFields
  }
}
    ${AdminUserGroupFieldsFragmentDoc}`;
export type RemoveDiplomMutationFn = Apollo.MutationFunction<RemoveDiplomMutation, RemoveDiplomMutationVariables>;

/**
 * __useRemoveDiplomMutation__
 *
 * To run a mutation, you first call `useRemoveDiplomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiplomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiplomMutation, { data, loading, error }] = useRemoveDiplomMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      filename: // value for 'filename'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveDiplomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDiplomMutation, RemoveDiplomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDiplomMutation, RemoveDiplomMutationVariables>(RemoveDiplomDocument, options);
      }
export type RemoveDiplomMutationHookResult = ReturnType<typeof useRemoveDiplomMutation>;
export type RemoveDiplomMutationResult = Apollo.MutationResult<RemoveDiplomMutation>;
export type RemoveDiplomMutationOptions = Apollo.BaseMutationOptions<RemoveDiplomMutation, RemoveDiplomMutationVariables>;
export const CreateTrainingDocument = gql`
    mutation CreateTraining($theme: String!, $subTheme: String!, $shortTheme: String!, $infoUrl: String!, $programPage: String!, $materialPage: String!) {
  createTraining(
    theme: $theme
    sub_theme: $subTheme
    short_theme: $shortTheme
    info_url: $infoUrl
    program_page: $programPage
    material_page: $materialPage
  ) {
    id
    theme
    sub_theme
    short_theme
    info_url
    program_page
    material_page
  }
}
    `;
export type CreateTrainingMutationFn = Apollo.MutationFunction<CreateTrainingMutation, CreateTrainingMutationVariables>;

/**
 * __useCreateTrainingMutation__
 *
 * To run a mutation, you first call `useCreateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingMutation, { data, loading, error }] = useCreateTrainingMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      subTheme: // value for 'subTheme'
 *      shortTheme: // value for 'shortTheme'
 *      infoUrl: // value for 'infoUrl'
 *      programPage: // value for 'programPage'
 *      materialPage: // value for 'materialPage'
 *   },
 * });
 */
export function useCreateTrainingMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrainingMutation, CreateTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrainingMutation, CreateTrainingMutationVariables>(CreateTrainingDocument, options);
      }
export type CreateTrainingMutationHookResult = ReturnType<typeof useCreateTrainingMutation>;
export type CreateTrainingMutationResult = Apollo.MutationResult<CreateTrainingMutation>;
export type CreateTrainingMutationOptions = Apollo.BaseMutationOptions<CreateTrainingMutation, CreateTrainingMutationVariables>;
export const UpdateTrainingDocument = gql`
    mutation UpdateTraining($trainingId: ID!, $theme: String!, $subTheme: String!, $shortTheme: String!, $infoUrl: String!, $programPage: String!, $materialPage: String!) {
  updateTraining(
    training_id: $trainingId
    theme: $theme
    sub_theme: $subTheme
    short_theme: $shortTheme
    info_url: $infoUrl
    program_page: $programPage
    material_page: $materialPage
  ) {
    id
    theme
    sub_theme
    short_theme
    info_url
    program_page
    material_page
  }
}
    `;
export type UpdateTrainingMutationFn = Apollo.MutationFunction<UpdateTrainingMutation, UpdateTrainingMutationVariables>;

/**
 * __useUpdateTrainingMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingMutation, { data, loading, error }] = useUpdateTrainingMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      theme: // value for 'theme'
 *      subTheme: // value for 'subTheme'
 *      shortTheme: // value for 'shortTheme'
 *      infoUrl: // value for 'infoUrl'
 *      programPage: // value for 'programPage'
 *      materialPage: // value for 'materialPage'
 *   },
 * });
 */
export function useUpdateTrainingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrainingMutation, UpdateTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrainingMutation, UpdateTrainingMutationVariables>(UpdateTrainingDocument, options);
      }
export type UpdateTrainingMutationHookResult = ReturnType<typeof useUpdateTrainingMutation>;
export type UpdateTrainingMutationResult = Apollo.MutationResult<UpdateTrainingMutation>;
export type UpdateTrainingMutationOptions = Apollo.BaseMutationOptions<UpdateTrainingMutation, UpdateTrainingMutationVariables>;
export const CreateLectionDocument = gql`
    mutation CreateLection($trainingId: ID!, $num: Int!, $theme: String!, $audio: String!, $video: String!, $moduleId: String!, $moduleParams: String!) {
  createLection(
    training_id: $trainingId
    num: $num
    theme: $theme
    audio: $audio
    video: $video
    module_id: $moduleId
    module_params: $moduleParams
  ) {
    id
    order
    num
    theme
    audio
    video
    module_id
    module_params
  }
}
    `;
export type CreateLectionMutationFn = Apollo.MutationFunction<CreateLectionMutation, CreateLectionMutationVariables>;

/**
 * __useCreateLectionMutation__
 *
 * To run a mutation, you first call `useCreateLectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLectionMutation, { data, loading, error }] = useCreateLectionMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      num: // value for 'num'
 *      theme: // value for 'theme'
 *      audio: // value for 'audio'
 *      video: // value for 'video'
 *      moduleId: // value for 'moduleId'
 *      moduleParams: // value for 'moduleParams'
 *   },
 * });
 */
export function useCreateLectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateLectionMutation, CreateLectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLectionMutation, CreateLectionMutationVariables>(CreateLectionDocument, options);
      }
export type CreateLectionMutationHookResult = ReturnType<typeof useCreateLectionMutation>;
export type CreateLectionMutationResult = Apollo.MutationResult<CreateLectionMutation>;
export type CreateLectionMutationOptions = Apollo.BaseMutationOptions<CreateLectionMutation, CreateLectionMutationVariables>;
export const UpdateLectionDocument = gql`
    mutation UpdateLection($trainingId: ID!, $lectionId: ID!, $num: Int!, $theme: String!, $audio: String!, $video: String!, $moduleId: String!, $moduleParams: String!) {
  updateLection(
    training_id: $trainingId
    lection_id: $lectionId
    num: $num
    theme: $theme
    audio: $audio
    video: $video
    module_id: $moduleId
    module_params: $moduleParams
  ) {
    id
    order
    theme
    num
    audio
    video
    module_id
    module_params
  }
}
    `;
export type UpdateLectionMutationFn = Apollo.MutationFunction<UpdateLectionMutation, UpdateLectionMutationVariables>;

/**
 * __useUpdateLectionMutation__
 *
 * To run a mutation, you first call `useUpdateLectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLectionMutation, { data, loading, error }] = useUpdateLectionMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      lectionId: // value for 'lectionId'
 *      num: // value for 'num'
 *      theme: // value for 'theme'
 *      audio: // value for 'audio'
 *      video: // value for 'video'
 *      moduleId: // value for 'moduleId'
 *      moduleParams: // value for 'moduleParams'
 *   },
 * });
 */
export function useUpdateLectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLectionMutation, UpdateLectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLectionMutation, UpdateLectionMutationVariables>(UpdateLectionDocument, options);
      }
export type UpdateLectionMutationHookResult = ReturnType<typeof useUpdateLectionMutation>;
export type UpdateLectionMutationResult = Apollo.MutationResult<UpdateLectionMutation>;
export type UpdateLectionMutationOptions = Apollo.BaseMutationOptions<UpdateLectionMutation, UpdateLectionMutationVariables>;
export const DeleteLectionDocument = gql`
    mutation DeleteLection($trainingId: ID!, $lectionId: ID!) {
  deleteLection(training_id: $trainingId, lection_id: $lectionId) {
    id
    message
  }
}
    `;
export type DeleteLectionMutationFn = Apollo.MutationFunction<DeleteLectionMutation, DeleteLectionMutationVariables>;

/**
 * __useDeleteLectionMutation__
 *
 * To run a mutation, you first call `useDeleteLectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLectionMutation, { data, loading, error }] = useDeleteLectionMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      lectionId: // value for 'lectionId'
 *   },
 * });
 */
export function useDeleteLectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLectionMutation, DeleteLectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLectionMutation, DeleteLectionMutationVariables>(DeleteLectionDocument, options);
      }
export type DeleteLectionMutationHookResult = ReturnType<typeof useDeleteLectionMutation>;
export type DeleteLectionMutationResult = Apollo.MutationResult<DeleteLectionMutation>;
export type DeleteLectionMutationOptions = Apollo.BaseMutationOptions<DeleteLectionMutation, DeleteLectionMutationVariables>;
export const MoveLectionDocument = gql`
    mutation MoveLection($trainingId: ID!, $lectionId: ID!, $direction: String) {
  moveLection(
    training_id: $trainingId
    lection_id: $lectionId
    direction: $direction
  ) {
    id
    message
  }
}
    `;
export type MoveLectionMutationFn = Apollo.MutationFunction<MoveLectionMutation, MoveLectionMutationVariables>;

/**
 * __useMoveLectionMutation__
 *
 * To run a mutation, you first call `useMoveLectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveLectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveLectionMutation, { data, loading, error }] = useMoveLectionMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      lectionId: // value for 'lectionId'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useMoveLectionMutation(baseOptions?: Apollo.MutationHookOptions<MoveLectionMutation, MoveLectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveLectionMutation, MoveLectionMutationVariables>(MoveLectionDocument, options);
      }
export type MoveLectionMutationHookResult = ReturnType<typeof useMoveLectionMutation>;
export type MoveLectionMutationResult = Apollo.MutationResult<MoveLectionMutation>;
export type MoveLectionMutationOptions = Apollo.BaseMutationOptions<MoveLectionMutation, MoveLectionMutationVariables>;
export const SaveAnswerDocument = gql`
    mutation SaveAnswer($lectionId: ID!, $groupId: ID!, $taskNum: Int!, $content: String!) {
  saveAnswer(
    lection_id: $lectionId
    group_id: $groupId
    task_num: $taskNum
    content: $content
  ) {
    id
    user_id
    lection_id
    group_id
    task_num
    content
  }
}
    `;
export type SaveAnswerMutationFn = Apollo.MutationFunction<SaveAnswerMutation, SaveAnswerMutationVariables>;

/**
 * __useSaveAnswerMutation__
 *
 * To run a mutation, you first call `useSaveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnswerMutation, { data, loading, error }] = useSaveAnswerMutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      taskNum: // value for 'taskNum'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSaveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SaveAnswerMutation, SaveAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAnswerMutation, SaveAnswerMutationVariables>(SaveAnswerDocument, options);
      }
export type SaveAnswerMutationHookResult = ReturnType<typeof useSaveAnswerMutation>;
export type SaveAnswerMutationResult = Apollo.MutationResult<SaveAnswerMutation>;
export type SaveAnswerMutationOptions = Apollo.BaseMutationOptions<SaveAnswerMutation, SaveAnswerMutationVariables>;
export const DeleteSavedAnswerDocument = gql`
    mutation DeleteSavedAnswer($answerId: ID!) {
  deleteSavedAnswer(answer_id: $answerId) {
    id
    message
  }
}
    `;
export type DeleteSavedAnswerMutationFn = Apollo.MutationFunction<DeleteSavedAnswerMutation, DeleteSavedAnswerMutationVariables>;

/**
 * __useDeleteSavedAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteSavedAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedAnswerMutation, { data, loading, error }] = useDeleteSavedAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useDeleteSavedAnswerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSavedAnswerMutation, DeleteSavedAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSavedAnswerMutation, DeleteSavedAnswerMutationVariables>(DeleteSavedAnswerDocument, options);
      }
export type DeleteSavedAnswerMutationHookResult = ReturnType<typeof useDeleteSavedAnswerMutation>;
export type DeleteSavedAnswerMutationResult = Apollo.MutationResult<DeleteSavedAnswerMutation>;
export type DeleteSavedAnswerMutationOptions = Apollo.BaseMutationOptions<DeleteSavedAnswerMutation, DeleteSavedAnswerMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($trainingId: ID!, $start_date: String!, $price: Float!) {
  createGroup(training_id: $trainingId, start_date: $start_date, price: $price) {
    id
    name
    price
    start_date
    end_date
    users_count
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      start_date: // value for 'start_date'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($groupId: ID!) {
  deleteGroup(group_id: $groupId) {
    id
    message
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($groupId: ID!, $trainingId: ID!, $start_date: String!, $price: Float!) {
  updateGroup(
    group_id: $groupId
    training_id: $trainingId
    start_date: $start_date
    price: $price
  ) {
    id
    name
    price
    start_date
    end_date
    users_count
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      trainingId: // value for 'trainingId'
 *      start_date: // value for 'start_date'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const SaveMediaTimeDocument = gql`
    mutation saveMediaTime($lection_id: ID!, $group_id: ID!, $time: Int!) {
  saveMediaTime(lection_id: $lection_id, group_id: $group_id, time: $time) {
    id
    group_id
    lection_id
    user_id
    time
  }
}
    `;
export type SaveMediaTimeMutationFn = Apollo.MutationFunction<SaveMediaTimeMutation, SaveMediaTimeMutationVariables>;

/**
 * __useSaveMediaTimeMutation__
 *
 * To run a mutation, you first call `useSaveMediaTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMediaTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMediaTimeMutation, { data, loading, error }] = useSaveMediaTimeMutation({
 *   variables: {
 *      lection_id: // value for 'lection_id'
 *      group_id: // value for 'group_id'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useSaveMediaTimeMutation(baseOptions?: Apollo.MutationHookOptions<SaveMediaTimeMutation, SaveMediaTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMediaTimeMutation, SaveMediaTimeMutationVariables>(SaveMediaTimeDocument, options);
      }
export type SaveMediaTimeMutationHookResult = ReturnType<typeof useSaveMediaTimeMutation>;
export type SaveMediaTimeMutationResult = Apollo.MutationResult<SaveMediaTimeMutation>;
export type SaveMediaTimeMutationOptions = Apollo.BaseMutationOptions<SaveMediaTimeMutation, SaveMediaTimeMutationVariables>;
export const DeleteSavedMediaTimeDocument = gql`
    mutation DeleteSavedMediaTime($id: ID!) {
  deleteSavedMediaTime(id: $id) {
    id
    message
  }
}
    `;
export type DeleteSavedMediaTimeMutationFn = Apollo.MutationFunction<DeleteSavedMediaTimeMutation, DeleteSavedMediaTimeMutationVariables>;

/**
 * __useDeleteSavedMediaTimeMutation__
 *
 * To run a mutation, you first call `useDeleteSavedMediaTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedMediaTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedMediaTimeMutation, { data, loading, error }] = useDeleteSavedMediaTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedMediaTimeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSavedMediaTimeMutation, DeleteSavedMediaTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSavedMediaTimeMutation, DeleteSavedMediaTimeMutationVariables>(DeleteSavedMediaTimeDocument, options);
      }
export type DeleteSavedMediaTimeMutationHookResult = ReturnType<typeof useDeleteSavedMediaTimeMutation>;
export type DeleteSavedMediaTimeMutationResult = Apollo.MutationResult<DeleteSavedMediaTimeMutation>;
export type DeleteSavedMediaTimeMutationOptions = Apollo.BaseMutationOptions<DeleteSavedMediaTimeMutation, DeleteSavedMediaTimeMutationVariables>;
export const CreateEmptyResultsDocument = gql`
    mutation createEmptyResults($groupId: ID!, $lectionId: ID!) {
  createEmptyResults(group_id: $groupId, lection_id: $lectionId)
}
    `;
export type CreateEmptyResultsMutationFn = Apollo.MutationFunction<CreateEmptyResultsMutation, CreateEmptyResultsMutationVariables>;

/**
 * __useCreateEmptyResultsMutation__
 *
 * To run a mutation, you first call `useCreateEmptyResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyResultsMutation, { data, loading, error }] = useCreateEmptyResultsMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      lectionId: // value for 'lectionId'
 *   },
 * });
 */
export function useCreateEmptyResultsMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmptyResultsMutation, CreateEmptyResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmptyResultsMutation, CreateEmptyResultsMutationVariables>(CreateEmptyResultsDocument, options);
      }
export type CreateEmptyResultsMutationHookResult = ReturnType<typeof useCreateEmptyResultsMutation>;
export type CreateEmptyResultsMutationResult = Apollo.MutationResult<CreateEmptyResultsMutation>;
export type CreateEmptyResultsMutationOptions = Apollo.BaseMutationOptions<CreateEmptyResultsMutation, CreateEmptyResultsMutationVariables>;
export const EditUserLectionResultsDocument = gql`
    mutation EditUserLectionResults($lectionId: ID!, $groupId: ID!, $userId: ID!, $taskNum: Int!, $results: TaskResultInput!) {
  editUserLectionResults(
    lection_id: $lectionId
    group_id: $groupId
    user_id: $userId
    task_num: $taskNum
    results: $results
  ) {
    id
    message
  }
}
    `;
export type EditUserLectionResultsMutationFn = Apollo.MutationFunction<EditUserLectionResultsMutation, EditUserLectionResultsMutationVariables>;

/**
 * __useEditUserLectionResultsMutation__
 *
 * To run a mutation, you first call `useEditUserLectionResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserLectionResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserLectionResultsMutation, { data, loading, error }] = useEditUserLectionResultsMutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *      taskNum: // value for 'taskNum'
 *      results: // value for 'results'
 *   },
 * });
 */
export function useEditUserLectionResultsMutation(baseOptions?: Apollo.MutationHookOptions<EditUserLectionResultsMutation, EditUserLectionResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserLectionResultsMutation, EditUserLectionResultsMutationVariables>(EditUserLectionResultsDocument, options);
      }
export type EditUserLectionResultsMutationHookResult = ReturnType<typeof useEditUserLectionResultsMutation>;
export type EditUserLectionResultsMutationResult = Apollo.MutationResult<EditUserLectionResultsMutation>;
export type EditUserLectionResultsMutationOptions = Apollo.BaseMutationOptions<EditUserLectionResultsMutation, EditUserLectionResultsMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    query Login($email: String!, $pass: String!) {
  login(email: $email, pass: $pass)
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const LoginUserForAdminDocument = gql`
    query LoginUserForAdmin($userId: ID!) {
  loginUserForAdmin(userId: $userId)
}
    `;

/**
 * __useLoginUserForAdminQuery__
 *
 * To run a query within a React component, call `useLoginUserForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUserForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUserForAdminQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLoginUserForAdminQuery(baseOptions: Apollo.QueryHookOptions<LoginUserForAdminQuery, LoginUserForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginUserForAdminQuery, LoginUserForAdminQueryVariables>(LoginUserForAdminDocument, options);
      }
export function useLoginUserForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginUserForAdminQuery, LoginUserForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginUserForAdminQuery, LoginUserForAdminQueryVariables>(LoginUserForAdminDocument, options);
        }
export type LoginUserForAdminQueryHookResult = ReturnType<typeof useLoginUserForAdminQuery>;
export type LoginUserForAdminLazyQueryHookResult = ReturnType<typeof useLoginUserForAdminLazyQuery>;
export type LoginUserForAdminQueryResult = Apollo.QueryResult<LoginUserForAdminQuery, LoginUserForAdminQueryVariables>;
export const RestorePasswordDocument = gql`
    query RestorePassword($email: String!) {
  restorePassword(email: $email) {
    id
    message
  }
}
    `;

/**
 * __useRestorePasswordQuery__
 *
 * To run a query within a React component, call `useRestorePasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestorePasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestorePasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRestorePasswordQuery(baseOptions: Apollo.QueryHookOptions<RestorePasswordQuery, RestorePasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RestorePasswordQuery, RestorePasswordQueryVariables>(RestorePasswordDocument, options);
      }
export function useRestorePasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RestorePasswordQuery, RestorePasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RestorePasswordQuery, RestorePasswordQueryVariables>(RestorePasswordDocument, options);
        }
export type RestorePasswordQueryHookResult = ReturnType<typeof useRestorePasswordQuery>;
export type RestorePasswordLazyQueryHookResult = ReturnType<typeof useRestorePasswordLazyQuery>;
export type RestorePasswordQueryResult = Apollo.QueryResult<RestorePasswordQuery, RestorePasswordQueryVariables>;
export const TrainingsForRegisterDocument = gql`
    query TrainingsForRegister {
  trainingsForRegister {
    id
    theme
    sub_theme
    info_url
    next_start_date
    duration
    min_price
    groups {
      id
      name
      date
    }
  }
}
    `;

/**
 * __useTrainingsForRegisterQuery__
 *
 * To run a query within a React component, call `useTrainingsForRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsForRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingsForRegisterQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingsForRegisterQuery(baseOptions?: Apollo.QueryHookOptions<TrainingsForRegisterQuery, TrainingsForRegisterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingsForRegisterQuery, TrainingsForRegisterQueryVariables>(TrainingsForRegisterDocument, options);
      }
export function useTrainingsForRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingsForRegisterQuery, TrainingsForRegisterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingsForRegisterQuery, TrainingsForRegisterQueryVariables>(TrainingsForRegisterDocument, options);
        }
export type TrainingsForRegisterQueryHookResult = ReturnType<typeof useTrainingsForRegisterQuery>;
export type TrainingsForRegisterLazyQueryHookResult = ReturnType<typeof useTrainingsForRegisterLazyQuery>;
export type TrainingsForRegisterQueryResult = Apollo.QueryResult<TrainingsForRegisterQuery, TrainingsForRegisterQueryVariables>;
export const UserLectionsDocument = gql`
    query UserLections($groupId: ID!) {
  userLections(group_id: $groupId) {
    lections {
      ...UserLectionFields
    }
    next_lection_date
  }
}
    ${UserLectionFieldsFragmentDoc}`;

/**
 * __useUserLectionsQuery__
 *
 * To run a query within a React component, call `useUserLectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLectionsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUserLectionsQuery(baseOptions: Apollo.QueryHookOptions<UserLectionsQuery, UserLectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLectionsQuery, UserLectionsQueryVariables>(UserLectionsDocument, options);
      }
export function useUserLectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLectionsQuery, UserLectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLectionsQuery, UserLectionsQueryVariables>(UserLectionsDocument, options);
        }
export type UserLectionsQueryHookResult = ReturnType<typeof useUserLectionsQuery>;
export type UserLectionsLazyQueryHookResult = ReturnType<typeof useUserLectionsLazyQuery>;
export type UserLectionsQueryResult = Apollo.QueryResult<UserLectionsQuery, UserLectionsQueryVariables>;
export const UserLectionDocument = gql`
    query UserLection($groupId: ID!, $lectionId: ID!) {
  userLection(group_id: $groupId, lection_id: $lectionId) {
    ...UserLectionFields
  }
}
    ${UserLectionFieldsFragmentDoc}`;

/**
 * __useUserLectionQuery__
 *
 * To run a query within a React component, call `useUserLectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLectionQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      lectionId: // value for 'lectionId'
 *   },
 * });
 */
export function useUserLectionQuery(baseOptions: Apollo.QueryHookOptions<UserLectionQuery, UserLectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLectionQuery, UserLectionQueryVariables>(UserLectionDocument, options);
      }
export function useUserLectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLectionQuery, UserLectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLectionQuery, UserLectionQueryVariables>(UserLectionDocument, options);
        }
export type UserLectionQueryHookResult = ReturnType<typeof useUserLectionQuery>;
export type UserLectionLazyQueryHookResult = ReturnType<typeof useUserLectionLazyQuery>;
export type UserLectionQueryResult = Apollo.QueryResult<UserLectionQuery, UserLectionQueryVariables>;
export const NextPrevLectionIdsDocument = gql`
    query NextPrevLectionIds($groupId: ID!, $lectionId: ID!) {
  nextPrevLectionIds(group_id: $groupId, lection_id: $lectionId) {
    next
    prev
  }
}
    `;

/**
 * __useNextPrevLectionIdsQuery__
 *
 * To run a query within a React component, call `useNextPrevLectionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextPrevLectionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextPrevLectionIdsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      lectionId: // value for 'lectionId'
 *   },
 * });
 */
export function useNextPrevLectionIdsQuery(baseOptions: Apollo.QueryHookOptions<NextPrevLectionIdsQuery, NextPrevLectionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextPrevLectionIdsQuery, NextPrevLectionIdsQueryVariables>(NextPrevLectionIdsDocument, options);
      }
export function useNextPrevLectionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextPrevLectionIdsQuery, NextPrevLectionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextPrevLectionIdsQuery, NextPrevLectionIdsQueryVariables>(NextPrevLectionIdsDocument, options);
        }
export type NextPrevLectionIdsQueryHookResult = ReturnType<typeof useNextPrevLectionIdsQuery>;
export type NextPrevLectionIdsLazyQueryHookResult = ReturnType<typeof useNextPrevLectionIdsLazyQuery>;
export type NextPrevLectionIdsQueryResult = Apollo.QueryResult<NextPrevLectionIdsQuery, NextPrevLectionIdsQueryVariables>;
export const M2ResultDocument = gql`
    query M2Result($groupId: ID!, $lectionId: ID!) {
  m2Result(group_id: $groupId, lection_id: $lectionId) {
    date
    task
    role
    points
  }
}
    `;

/**
 * __useM2ResultQuery__
 *
 * To run a query within a React component, call `useM2ResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useM2ResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM2ResultQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      lectionId: // value for 'lectionId'
 *   },
 * });
 */
export function useM2ResultQuery(baseOptions: Apollo.QueryHookOptions<M2ResultQuery, M2ResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M2ResultQuery, M2ResultQueryVariables>(M2ResultDocument, options);
      }
export function useM2ResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M2ResultQuery, M2ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M2ResultQuery, M2ResultQueryVariables>(M2ResultDocument, options);
        }
export type M2ResultQueryHookResult = ReturnType<typeof useM2ResultQuery>;
export type M2ResultLazyQueryHookResult = ReturnType<typeof useM2ResultLazyQuery>;
export type M2ResultQueryResult = Apollo.QueryResult<M2ResultQuery, M2ResultQueryVariables>;
export const UserGroupDocument = gql`
    query UserGroup($userId: ID!, $groupId: ID!) {
  userGroup(user_id: $userId, group_id: $groupId) {
    id
    name
    price
    start_date
    training_id
    payment {
      status
      amount
      price
    }
    diploma
  }
}
    `;

/**
 * __useUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUserGroupQuery(baseOptions: Apollo.QueryHookOptions<UserGroupQuery, UserGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
      }
export function useUserGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupQuery, UserGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
        }
export type UserGroupQueryHookResult = ReturnType<typeof useUserGroupQuery>;
export type UserGroupLazyQueryHookResult = ReturnType<typeof useUserGroupLazyQuery>;
export type UserGroupQueryResult = Apollo.QueryResult<UserGroupQuery, UserGroupQueryVariables>;
export const RegisterForTrainingDocument = gql`
    mutation RegisterForTraining($groupId: ID!) {
  registerForTraining(group_id: $groupId) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type RegisterForTrainingMutationFn = Apollo.MutationFunction<RegisterForTrainingMutation, RegisterForTrainingMutationVariables>;

/**
 * __useRegisterForTrainingMutation__
 *
 * To run a mutation, you first call `useRegisterForTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForTrainingMutation, { data, loading, error }] = useRegisterForTrainingMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useRegisterForTrainingMutation(baseOptions?: Apollo.MutationHookOptions<RegisterForTrainingMutation, RegisterForTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterForTrainingMutation, RegisterForTrainingMutationVariables>(RegisterForTrainingDocument, options);
      }
export type RegisterForTrainingMutationHookResult = ReturnType<typeof useRegisterForTrainingMutation>;
export type RegisterForTrainingMutationResult = Apollo.MutationResult<RegisterForTrainingMutation>;
export type RegisterForTrainingMutationOptions = Apollo.BaseMutationOptions<RegisterForTrainingMutation, RegisterForTrainingMutationVariables>;
export const ProgressDocument = gql`
    query Progress($groupId: ID!) {
  progress(group_id: $groupId) {
    lection
    lection_total
    points
    cup_best_answer
    cup_best_comment
    cup_best_phrase
    current_lection_id
  }
}
    `;

/**
 * __useProgressQuery__
 *
 * To run a query within a React component, call `useProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgressQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useProgressQuery(baseOptions: Apollo.QueryHookOptions<ProgressQuery, ProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgressQuery, ProgressQueryVariables>(ProgressDocument, options);
      }
export function useProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgressQuery, ProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgressQuery, ProgressQueryVariables>(ProgressDocument, options);
        }
export type ProgressQueryHookResult = ReturnType<typeof useProgressQuery>;
export type ProgressLazyQueryHookResult = ReturnType<typeof useProgressLazyQuery>;
export type ProgressQueryResult = Apollo.QueryResult<ProgressQuery, ProgressQueryVariables>;
export const PageDocument = gql`
    query Page($name: String!) {
  page(name: $name) {
    id
    name
    html
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PagesDocument = gql`
    query pages {
  pages {
    id
    name
    html
  }
}
    `;

/**
 * __usePagesQuery__
 *
 * To run a query within a React component, call `usePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePagesQuery(baseOptions?: Apollo.QueryHookOptions<PagesQuery, PagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PagesQuery, PagesQueryVariables>(PagesDocument, options);
      }
export function usePagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagesQuery, PagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PagesQuery, PagesQueryVariables>(PagesDocument, options);
        }
export type PagesQueryHookResult = ReturnType<typeof usePagesQuery>;
export type PagesLazyQueryHookResult = ReturnType<typeof usePagesLazyQuery>;
export type PagesQueryResult = Apollo.QueryResult<PagesQuery, PagesQueryVariables>;
export const UserStatisticDocument = gql`
    query UserStatistic($groupId: ID!) {
  userStatistic(group_id: $groupId) {
    points {
      diploma
      name
      avgMy
      avg
      avgTen
      diplomaGold
    }
    topTen
    myPoints
    rankPlace
    bestAnswer {
      lection_id
      lection_num
    }
    bestComment {
      lection_id
      lection_num
    }
    bestPhrase {
      lection_id
      lection_num
    }
  }
}
    `;

/**
 * __useUserStatisticQuery__
 *
 * To run a query within a React component, call `useUserStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatisticQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUserStatisticQuery(baseOptions: Apollo.QueryHookOptions<UserStatisticQuery, UserStatisticQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserStatisticQuery, UserStatisticQueryVariables>(UserStatisticDocument, options);
      }
export function useUserStatisticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserStatisticQuery, UserStatisticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserStatisticQuery, UserStatisticQueryVariables>(UserStatisticDocument, options);
        }
export type UserStatisticQueryHookResult = ReturnType<typeof useUserStatisticQuery>;
export type UserStatisticLazyQueryHookResult = ReturnType<typeof useUserStatisticLazyQuery>;
export type UserStatisticQueryResult = Apollo.QueryResult<UserStatisticQuery, UserStatisticQueryVariables>;
export const TrainingPageDocument = gql`
    query TrainingPage($groupId: ID!, $pageId: String!) {
  trainingPage(group_id: $groupId, page_id: $pageId)
}
    `;

/**
 * __useTrainingPageQuery__
 *
 * To run a query within a React component, call `useTrainingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingPageQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useTrainingPageQuery(baseOptions: Apollo.QueryHookOptions<TrainingPageQuery, TrainingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingPageQuery, TrainingPageQueryVariables>(TrainingPageDocument, options);
      }
export function useTrainingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingPageQuery, TrainingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingPageQuery, TrainingPageQueryVariables>(TrainingPageDocument, options);
        }
export type TrainingPageQueryHookResult = ReturnType<typeof useTrainingPageQuery>;
export type TrainingPageLazyQueryHookResult = ReturnType<typeof useTrainingPageLazyQuery>;
export type TrainingPageQueryResult = Apollo.QueryResult<TrainingPageQuery, TrainingPageQueryVariables>;
export const UserDiplomsDocument = gql`
    query UserDiploms {
  userDiploms {
    diploma
    name
  }
}
    `;

/**
 * __useUserDiplomsQuery__
 *
 * To run a query within a React component, call `useUserDiplomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDiplomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDiplomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDiplomsQuery(baseOptions?: Apollo.QueryHookOptions<UserDiplomsQuery, UserDiplomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDiplomsQuery, UserDiplomsQueryVariables>(UserDiplomsDocument, options);
      }
export function useUserDiplomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDiplomsQuery, UserDiplomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDiplomsQuery, UserDiplomsQueryVariables>(UserDiplomsDocument, options);
        }
export type UserDiplomsQueryHookResult = ReturnType<typeof useUserDiplomsQuery>;
export type UserDiplomsLazyQueryHookResult = ReturnType<typeof useUserDiplomsLazyQuery>;
export type UserDiplomsQueryResult = Apollo.QueryResult<UserDiplomsQuery, UserDiplomsQueryVariables>;
export const UsersDocument = gql`
    query Users($userName: String, $groupId: ID!, $offset: Int, $limit: Int, $sort: String) {
  users(
    userName: $userName
    groupId: $groupId
    offset: $offset
    limit: $limit
    sort: $sort
  ) {
    results {
      ...UserFields
    }
    total
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      groupId: // value for 'groupId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UsersForDialogsDocument = gql`
    query UsersForDialogs($userName: String, $groupId: ID!, $offset: Int, $limit: Int, $sort: String) {
  users(
    userName: $userName
    groupId: $groupId
    offset: $offset
    limit: $limit
    sort: $sort
  ) {
    results {
      id
      name
    }
  }
}
    `;

/**
 * __useUsersForDialogsQuery__
 *
 * To run a query within a React component, call `useUsersForDialogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersForDialogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersForDialogsQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      groupId: // value for 'groupId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersForDialogsQuery(baseOptions: Apollo.QueryHookOptions<UsersForDialogsQuery, UsersForDialogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersForDialogsQuery, UsersForDialogsQueryVariables>(UsersForDialogsDocument, options);
      }
export function useUsersForDialogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersForDialogsQuery, UsersForDialogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersForDialogsQuery, UsersForDialogsQueryVariables>(UsersForDialogsDocument, options);
        }
export type UsersForDialogsQueryHookResult = ReturnType<typeof useUsersForDialogsQuery>;
export type UsersForDialogsLazyQueryHookResult = ReturnType<typeof useUsersForDialogsLazyQuery>;
export type UsersForDialogsQueryResult = Apollo.QueryResult<UsersForDialogsQuery, UsersForDialogsQueryVariables>;
export const DialogsDocument = gql`
    query Dialogs($offset: Int, $limit: Int, $sort: String) {
  dialogs(offset: $offset, limit: $limit, sort: $sort) {
    results {
      ...DialogFields
    }
    total
  }
}
    ${DialogFieldsFragmentDoc}`;

/**
 * __useDialogsQuery__
 *
 * To run a query within a React component, call `useDialogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDialogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDialogsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDialogsQuery(baseOptions?: Apollo.QueryHookOptions<DialogsQuery, DialogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DialogsQuery, DialogsQueryVariables>(DialogsDocument, options);
      }
export function useDialogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DialogsQuery, DialogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DialogsQuery, DialogsQueryVariables>(DialogsDocument, options);
        }
export type DialogsQueryHookResult = ReturnType<typeof useDialogsQuery>;
export type DialogsLazyQueryHookResult = ReturnType<typeof useDialogsLazyQuery>;
export type DialogsQueryResult = Apollo.QueryResult<DialogsQuery, DialogsQueryVariables>;
export const DialogDocument = gql`
    query Dialog($id: ID!) {
  dialog(id: $id) {
    id
    members {
      membersCode
      membersName
    }
    num
    date
    messages {
      id
      author_id
      author_name
      dialog_id
      date
      text
      attachements
    }
  }
}
    `;

/**
 * __useDialogQuery__
 *
 * To run a query within a React component, call `useDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDialogQuery(baseOptions: Apollo.QueryHookOptions<DialogQuery, DialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DialogQuery, DialogQueryVariables>(DialogDocument, options);
      }
export function useDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DialogQuery, DialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DialogQuery, DialogQueryVariables>(DialogDocument, options);
        }
export type DialogQueryHookResult = ReturnType<typeof useDialogQuery>;
export type DialogLazyQueryHookResult = ReturnType<typeof useDialogLazyQuery>;
export type DialogQueryResult = Apollo.QueryResult<DialogQuery, DialogQueryVariables>;
export const AllUnviewedMessegesDocument = gql`
    query AllUnviewedMesseges {
  allUnviewedMesseges
}
    `;

/**
 * __useAllUnviewedMessegesQuery__
 *
 * To run a query within a React component, call `useAllUnviewedMessegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUnviewedMessegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUnviewedMessegesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUnviewedMessegesQuery(baseOptions?: Apollo.QueryHookOptions<AllUnviewedMessegesQuery, AllUnviewedMessegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUnviewedMessegesQuery, AllUnviewedMessegesQueryVariables>(AllUnviewedMessegesDocument, options);
      }
export function useAllUnviewedMessegesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUnviewedMessegesQuery, AllUnviewedMessegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUnviewedMessegesQuery, AllUnviewedMessegesQueryVariables>(AllUnviewedMessegesDocument, options);
        }
export type AllUnviewedMessegesQueryHookResult = ReturnType<typeof useAllUnviewedMessegesQuery>;
export type AllUnviewedMessegesLazyQueryHookResult = ReturnType<typeof useAllUnviewedMessegesLazyQuery>;
export type AllUnviewedMessegesQueryResult = Apollo.QueryResult<AllUnviewedMessegesQuery, AllUnviewedMessegesQueryVariables>;
export const GroupListDocument = gql`
    query GroupList {
  groupList {
    id
    name
  }
}
    `;

/**
 * __useGroupListQuery__
 *
 * To run a query within a React component, call `useGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupListQuery(baseOptions?: Apollo.QueryHookOptions<GroupListQuery, GroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupListQuery, GroupListQueryVariables>(GroupListDocument, options);
      }
export function useGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupListQuery, GroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupListQuery, GroupListQueryVariables>(GroupListDocument, options);
        }
export type GroupListQueryHookResult = ReturnType<typeof useGroupListQuery>;
export type GroupListLazyQueryHookResult = ReturnType<typeof useGroupListLazyQuery>;
export type GroupListQueryResult = Apollo.QueryResult<GroupListQuery, GroupListQueryVariables>;
export const GroupsDocument = gql`
    query Groups($offset: Int, $limit: Int, $sort: String, $date: String) {
  groups(offset: $offset, limit: $limit, sort: $sort, date: $date) {
    groups {
      id
      name
      price
      start_date
      end_date
      users_count
    }
    total
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const GroupDocument = gql`
    query Group($groupId: ID!) {
  group(id: $groupId) {
    id
    name
    price
    start_date
    end_date
    users_count
  }
}
    `;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupEndDateDocument = gql`
    query GroupEndDate($groupId: ID!) {
  groupEndDate(id: $groupId) {
    id
    end_date
  }
}
    `;

/**
 * __useGroupEndDateQuery__
 *
 * To run a query within a React component, call `useGroupEndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupEndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupEndDateQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupEndDateQuery(baseOptions: Apollo.QueryHookOptions<GroupEndDateQuery, GroupEndDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupEndDateQuery, GroupEndDateQueryVariables>(GroupEndDateDocument, options);
      }
export function useGroupEndDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupEndDateQuery, GroupEndDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupEndDateQuery, GroupEndDateQueryVariables>(GroupEndDateDocument, options);
        }
export type GroupEndDateQueryHookResult = ReturnType<typeof useGroupEndDateQuery>;
export type GroupEndDateLazyQueryHookResult = ReturnType<typeof useGroupEndDateLazyQuery>;
export type GroupEndDateQueryResult = Apollo.QueryResult<GroupEndDateQuery, GroupEndDateQueryVariables>;
export const AdminUserGroupsDocument = gql`
    query AdminUserGroups($userId: ID!) {
  adminUserGroups(user_id: $userId) {
    id
    name
  }
}
    `;

/**
 * __useAdminUserGroupsQuery__
 *
 * To run a query within a React component, call `useAdminUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserGroupsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserGroupsQuery(baseOptions: Apollo.QueryHookOptions<AdminUserGroupsQuery, AdminUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserGroupsQuery, AdminUserGroupsQueryVariables>(AdminUserGroupsDocument, options);
      }
export function useAdminUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserGroupsQuery, AdminUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserGroupsQuery, AdminUserGroupsQueryVariables>(AdminUserGroupsDocument, options);
        }
export type AdminUserGroupsQueryHookResult = ReturnType<typeof useAdminUserGroupsQuery>;
export type AdminUserGroupsLazyQueryHookResult = ReturnType<typeof useAdminUserGroupsLazyQuery>;
export type AdminUserGroupsQueryResult = Apollo.QueryResult<AdminUserGroupsQuery, AdminUserGroupsQueryVariables>;
export const UserInfoNameDocument = gql`
    query UserInfoName($userId: ID!) {
  userInfo(id: $userId) {
    name
    id
  }
}
    `;

/**
 * __useUserInfoNameQuery__
 *
 * To run a query within a React component, call `useUserInfoNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoNameQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserInfoNameQuery(baseOptions: Apollo.QueryHookOptions<UserInfoNameQuery, UserInfoNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoNameQuery, UserInfoNameQueryVariables>(UserInfoNameDocument, options);
      }
export function useUserInfoNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoNameQuery, UserInfoNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoNameQuery, UserInfoNameQueryVariables>(UserInfoNameDocument, options);
        }
export type UserInfoNameQueryHookResult = ReturnType<typeof useUserInfoNameQuery>;
export type UserInfoNameLazyQueryHookResult = ReturnType<typeof useUserInfoNameLazyQuery>;
export type UserInfoNameQueryResult = Apollo.QueryResult<UserInfoNameQuery, UserInfoNameQueryVariables>;
export const UserInfoDocument = gql`
    query UserInfo($userId: ID!) {
  userInfo(id: $userId) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const TrainingsDocument = gql`
    query Trainings {
  trainings {
    id
    theme
    sub_theme
    short_theme
    program_page
    material_page
    lection_count
    group_ended
    group_current
    group_coming
  }
}
    `;

/**
 * __useTrainingsQuery__
 *
 * To run a query within a React component, call `useTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingsQuery(baseOptions?: Apollo.QueryHookOptions<TrainingsQuery, TrainingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, options);
      }
export function useTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingsQuery, TrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, options);
        }
export type TrainingsQueryHookResult = ReturnType<typeof useTrainingsQuery>;
export type TrainingsLazyQueryHookResult = ReturnType<typeof useTrainingsLazyQuery>;
export type TrainingsQueryResult = Apollo.QueryResult<TrainingsQuery, TrainingsQueryVariables>;
export const TrainingDocument = gql`
    query Training($trainingId: ID!) {
  training(id: $trainingId) {
    id
    theme
    sub_theme
    short_theme
    info_url
    program_page
    material_page
  }
}
    `;

/**
 * __useTrainingQuery__
 *
 * To run a query within a React component, call `useTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useTrainingQuery(baseOptions: Apollo.QueryHookOptions<TrainingQuery, TrainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainingQuery, TrainingQueryVariables>(TrainingDocument, options);
      }
export function useTrainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingQuery, TrainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainingQuery, TrainingQueryVariables>(TrainingDocument, options);
        }
export type TrainingQueryHookResult = ReturnType<typeof useTrainingQuery>;
export type TrainingLazyQueryHookResult = ReturnType<typeof useTrainingLazyQuery>;
export type TrainingQueryResult = Apollo.QueryResult<TrainingQuery, TrainingQueryVariables>;
export const LectionsDocument = gql`
    query Lections($trainingId: ID!) {
  lections(training_id: $trainingId) {
    id
    order
    num
    theme
    audio
    video
    module_id
    module_params
  }
}
    `;

/**
 * __useLectionsQuery__
 *
 * To run a query within a React component, call `useLectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLectionsQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useLectionsQuery(baseOptions: Apollo.QueryHookOptions<LectionsQuery, LectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LectionsQuery, LectionsQueryVariables>(LectionsDocument, options);
      }
export function useLectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LectionsQuery, LectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LectionsQuery, LectionsQueryVariables>(LectionsDocument, options);
        }
export type LectionsQueryHookResult = ReturnType<typeof useLectionsQuery>;
export type LectionsLazyQueryHookResult = ReturnType<typeof useLectionsLazyQuery>;
export type LectionsQueryResult = Apollo.QueryResult<LectionsQuery, LectionsQueryVariables>;
export const SavedAnswerDocument = gql`
    query SavedAnswer($lectionId: ID!, $groupId: ID!, $taskNum: Int!) {
  savedAnswer(lection_id: $lectionId, group_id: $groupId, task_num: $taskNum) {
    id
    user_id
    lection_id
    group_id
    task_num
    content
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useSavedAnswerQuery__
 *
 * To run a query within a React component, call `useSavedAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedAnswerQuery({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      taskNum: // value for 'taskNum'
 *   },
 * });
 */
export function useSavedAnswerQuery(baseOptions: Apollo.QueryHookOptions<SavedAnswerQuery, SavedAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedAnswerQuery, SavedAnswerQueryVariables>(SavedAnswerDocument, options);
      }
export function useSavedAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedAnswerQuery, SavedAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedAnswerQuery, SavedAnswerQueryVariables>(SavedAnswerDocument, options);
        }
export type SavedAnswerQueryHookResult = ReturnType<typeof useSavedAnswerQuery>;
export type SavedAnswerLazyQueryHookResult = ReturnType<typeof useSavedAnswerLazyQuery>;
export type SavedAnswerQueryResult = Apollo.QueryResult<SavedAnswerQuery, SavedAnswerQueryVariables>;
export const SavedMediaTimeDocument = gql`
    query SavedMediaTime($lection_id: ID!, $group_id: ID!) {
  savedMediaTime(lection_id: $lection_id, group_id: $group_id) {
    id
    group_id
    lection_id
    user_id
    time
  }
}
    `;

/**
 * __useSavedMediaTimeQuery__
 *
 * To run a query within a React component, call `useSavedMediaTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedMediaTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedMediaTimeQuery({
 *   variables: {
 *      lection_id: // value for 'lection_id'
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useSavedMediaTimeQuery(baseOptions: Apollo.QueryHookOptions<SavedMediaTimeQuery, SavedMediaTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedMediaTimeQuery, SavedMediaTimeQueryVariables>(SavedMediaTimeDocument, options);
      }
export function useSavedMediaTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedMediaTimeQuery, SavedMediaTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedMediaTimeQuery, SavedMediaTimeQueryVariables>(SavedMediaTimeDocument, options);
        }
export type SavedMediaTimeQueryHookResult = ReturnType<typeof useSavedMediaTimeQuery>;
export type SavedMediaTimeLazyQueryHookResult = ReturnType<typeof useSavedMediaTimeLazyQuery>;
export type SavedMediaTimeQueryResult = Apollo.QueryResult<SavedMediaTimeQuery, SavedMediaTimeQueryVariables>;
export const GroupStatisticsDocument = gql`
    query GroupStatistics($groupId: ID!) {
  groupStatistics(group_id: $groupId) {
    lectionsStatistics {
      lectionId
      num
      minResult
      averageResult
      maxResult
      step1
      step2
      step3
      step4
      allSteps
    }
    usersRating {
      id
      name
      email
      points
      position
    }
  }
}
    `;

/**
 * __useGroupStatisticsQuery__
 *
 * To run a query within a React component, call `useGroupStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupStatisticsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GroupStatisticsQuery, GroupStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupStatisticsQuery, GroupStatisticsQueryVariables>(GroupStatisticsDocument, options);
      }
export function useGroupStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupStatisticsQuery, GroupStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupStatisticsQuery, GroupStatisticsQueryVariables>(GroupStatisticsDocument, options);
        }
export type GroupStatisticsQueryHookResult = ReturnType<typeof useGroupStatisticsQuery>;
export type GroupStatisticsLazyQueryHookResult = ReturnType<typeof useGroupStatisticsLazyQuery>;
export type GroupStatisticsQueryResult = Apollo.QueryResult<GroupStatisticsQuery, GroupStatisticsQueryVariables>;
export const GroupLectionsListDocument = gql`
    query GroupLectionsList($groupId: ID!) {
  groupLectionsList(group_id: $groupId) {
    group {
      id
      name
      start_date
      end_date
      users_count
    }
    lections {
      id
      module_id
      num
      status
      theme
      usersWithAccess
      usersWithResults
    }
  }
}
    `;

/**
 * __useGroupLectionsListQuery__
 *
 * To run a query within a React component, call `useGroupLectionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupLectionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupLectionsListQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupLectionsListQuery(baseOptions: Apollo.QueryHookOptions<GroupLectionsListQuery, GroupLectionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupLectionsListQuery, GroupLectionsListQueryVariables>(GroupLectionsListDocument, options);
      }
export function useGroupLectionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupLectionsListQuery, GroupLectionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupLectionsListQuery, GroupLectionsListQueryVariables>(GroupLectionsListDocument, options);
        }
export type GroupLectionsListQueryHookResult = ReturnType<typeof useGroupLectionsListQuery>;
export type GroupLectionsListLazyQueryHookResult = ReturnType<typeof useGroupLectionsListLazyQuery>;
export type GroupLectionsListQueryResult = Apollo.QueryResult<GroupLectionsListQuery, GroupLectionsListQueryVariables>;
export const AdminUserLectionResultsDocument = gql`
    query AdminUserLectionResults($groupId: ID!, $userId: ID!) {
  adminUserLectionResults(group_id: $groupId, user_id: $userId) {
    lection_id
    module_id
    num
    theme
    lection_result {
      num
      points
      points_activity
      cup_best_answer
      cup_best_comment
      cup_best_phrase
    }
  }
}
    `;

/**
 * __useAdminUserLectionResultsQuery__
 *
 * To run a query within a React component, call `useAdminUserLectionResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserLectionResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserLectionResultsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserLectionResultsQuery(baseOptions: Apollo.QueryHookOptions<AdminUserLectionResultsQuery, AdminUserLectionResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserLectionResultsQuery, AdminUserLectionResultsQueryVariables>(AdminUserLectionResultsDocument, options);
      }
export function useAdminUserLectionResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserLectionResultsQuery, AdminUserLectionResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserLectionResultsQuery, AdminUserLectionResultsQueryVariables>(AdminUserLectionResultsDocument, options);
        }
export type AdminUserLectionResultsQueryHookResult = ReturnType<typeof useAdminUserLectionResultsQuery>;
export type AdminUserLectionResultsLazyQueryHookResult = ReturnType<typeof useAdminUserLectionResultsLazyQuery>;
export type AdminUserLectionResultsQueryResult = Apollo.QueryResult<AdminUserLectionResultsQuery, AdminUserLectionResultsQueryVariables>;
export const CountryFlagCodeDocument = gql`
    query CountryFlagCode($userIp: String!) {
  countryFlagCode(userIp: $userIp)
}
    `;

/**
 * __useCountryFlagCodeQuery__
 *
 * To run a query within a React component, call `useCountryFlagCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryFlagCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryFlagCodeQuery({
 *   variables: {
 *      userIp: // value for 'userIp'
 *   },
 * });
 */
export function useCountryFlagCodeQuery(baseOptions: Apollo.QueryHookOptions<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>(CountryFlagCodeDocument, options);
      }
export function useCountryFlagCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>(CountryFlagCodeDocument, options);
        }
export type CountryFlagCodeQueryHookResult = ReturnType<typeof useCountryFlagCodeQuery>;
export type CountryFlagCodeLazyQueryHookResult = ReturnType<typeof useCountryFlagCodeLazyQuery>;
export type CountryFlagCodeQueryResult = Apollo.QueryResult<CountryFlagCodeQuery, CountryFlagCodeQueryVariables>;
export const MessageToSchoolDocument = gql`
    query MessageToSchool($name: String!, $email: String!, $phone: String!, $training: String!, $date: String!, $amountToPay: String!, $message_requisites: String, $file: String, $filename: String) {
  messageToSchool(
    name: $name
    email: $email
    phone: $phone
    training: $training
    date: $date
    amountToPay: $amountToPay
    message_requisites: $message_requisites
    file: $file
    filename: $filename
  ) {
    id
    message
  }
}
    `;

/**
 * __useMessageToSchoolQuery__
 *
 * To run a query within a React component, call `useMessageToSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageToSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageToSchoolQuery({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      training: // value for 'training'
 *      date: // value for 'date'
 *      amountToPay: // value for 'amountToPay'
 *      message_requisites: // value for 'message_requisites'
 *      file: // value for 'file'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useMessageToSchoolQuery(baseOptions: Apollo.QueryHookOptions<MessageToSchoolQuery, MessageToSchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageToSchoolQuery, MessageToSchoolQueryVariables>(MessageToSchoolDocument, options);
      }
export function useMessageToSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageToSchoolQuery, MessageToSchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageToSchoolQuery, MessageToSchoolQueryVariables>(MessageToSchoolDocument, options);
        }
export type MessageToSchoolQueryHookResult = ReturnType<typeof useMessageToSchoolQuery>;
export type MessageToSchoolLazyQueryHookResult = ReturnType<typeof useMessageToSchoolLazyQuery>;
export type MessageToSchoolQueryResult = Apollo.QueryResult<MessageToSchoolQuery, MessageToSchoolQueryVariables>;
export const AddM1Task1Document = gql`
    mutation AddM1Task1($lectionId: ID!, $groupId: ID!, $answer: String!, $answer2: String!) {
  addM1Task1(
    lection_id: $lectionId
    group_id: $groupId
    answer: $answer
    answer2: $answer2
  ) {
    id
    message
  }
}
    `;
export type AddM1Task1MutationFn = Apollo.MutationFunction<AddM1Task1Mutation, AddM1Task1MutationVariables>;

/**
 * __useAddM1Task1Mutation__
 *
 * To run a mutation, you first call `useAddM1Task1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM1Task1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM1Task1Mutation, { data, loading, error }] = useAddM1Task1Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      answer: // value for 'answer'
 *      answer2: // value for 'answer2'
 *   },
 * });
 */
export function useAddM1Task1Mutation(baseOptions?: Apollo.MutationHookOptions<AddM1Task1Mutation, AddM1Task1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM1Task1Mutation, AddM1Task1MutationVariables>(AddM1Task1Document, options);
      }
export type AddM1Task1MutationHookResult = ReturnType<typeof useAddM1Task1Mutation>;
export type AddM1Task1MutationResult = Apollo.MutationResult<AddM1Task1Mutation>;
export type AddM1Task1MutationOptions = Apollo.BaseMutationOptions<AddM1Task1Mutation, AddM1Task1MutationVariables>;
export const AddM1Task2Document = gql`
    mutation AddM1Task2($lectionId: ID!, $groupId: ID!, $comments: [Task2Comment]!) {
  addM1Task2(lection_id: $lectionId, group_id: $groupId, comments: $comments) {
    id
    message
  }
}
    `;
export type AddM1Task2MutationFn = Apollo.MutationFunction<AddM1Task2Mutation, AddM1Task2MutationVariables>;

/**
 * __useAddM1Task2Mutation__
 *
 * To run a mutation, you first call `useAddM1Task2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM1Task2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM1Task2Mutation, { data, loading, error }] = useAddM1Task2Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function useAddM1Task2Mutation(baseOptions?: Apollo.MutationHookOptions<AddM1Task2Mutation, AddM1Task2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM1Task2Mutation, AddM1Task2MutationVariables>(AddM1Task2Document, options);
      }
export type AddM1Task2MutationHookResult = ReturnType<typeof useAddM1Task2Mutation>;
export type AddM1Task2MutationResult = Apollo.MutationResult<AddM1Task2Mutation>;
export type AddM1Task2MutationOptions = Apollo.BaseMutationOptions<AddM1Task2Mutation, AddM1Task2MutationVariables>;
export const AddM1Task3Document = gql`
    mutation AddM1Task3($lectionId: ID!, $groupId: ID!, $evaluation: Task3EvaluationInput!) {
  addM1Task3(lection_id: $lectionId, group_id: $groupId, evaluation: $evaluation) {
    id
    message
  }
}
    `;
export type AddM1Task3MutationFn = Apollo.MutationFunction<AddM1Task3Mutation, AddM1Task3MutationVariables>;

/**
 * __useAddM1Task3Mutation__
 *
 * To run a mutation, you first call `useAddM1Task3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM1Task3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM1Task3Mutation, { data, loading, error }] = useAddM1Task3Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      evaluation: // value for 'evaluation'
 *   },
 * });
 */
export function useAddM1Task3Mutation(baseOptions?: Apollo.MutationHookOptions<AddM1Task3Mutation, AddM1Task3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM1Task3Mutation, AddM1Task3MutationVariables>(AddM1Task3Document, options);
      }
export type AddM1Task3MutationHookResult = ReturnType<typeof useAddM1Task3Mutation>;
export type AddM1Task3MutationResult = Apollo.MutationResult<AddM1Task3Mutation>;
export type AddM1Task3MutationOptions = Apollo.BaseMutationOptions<AddM1Task3Mutation, AddM1Task3MutationVariables>;
export const AddM1Task4Document = gql`
    mutation AddM1Task4($lectionId: ID!, $groupId: ID!, $evaluationComments: [Task4EvaluationCommentInput]!) {
  addM1Task4(
    lection_id: $lectionId
    group_id: $groupId
    evaluation_comments: $evaluationComments
  ) {
    id
    message
  }
}
    `;
export type AddM1Task4MutationFn = Apollo.MutationFunction<AddM1Task4Mutation, AddM1Task4MutationVariables>;

/**
 * __useAddM1Task4Mutation__
 *
 * To run a mutation, you first call `useAddM1Task4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM1Task4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM1Task4Mutation, { data, loading, error }] = useAddM1Task4Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      evaluationComments: // value for 'evaluationComments'
 *   },
 * });
 */
export function useAddM1Task4Mutation(baseOptions?: Apollo.MutationHookOptions<AddM1Task4Mutation, AddM1Task4MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM1Task4Mutation, AddM1Task4MutationVariables>(AddM1Task4Document, options);
      }
export type AddM1Task4MutationHookResult = ReturnType<typeof useAddM1Task4Mutation>;
export type AddM1Task4MutationResult = Apollo.MutationResult<AddM1Task4Mutation>;
export type AddM1Task4MutationOptions = Apollo.BaseMutationOptions<AddM1Task4Mutation, AddM1Task4MutationVariables>;
export const M1Task1Document = gql`
    query M1Task1($lectionId: ID!, $groupId: ID!) {
  m1Task1(lection_id: $lectionId, group_id: $groupId) {
    question
    answer
    answer2
  }
}
    `;

/**
 * __useM1Task1Query__
 *
 * To run a query within a React component, call `useM1Task1Query` and pass it any options that fit your needs.
 * When your component renders, `useM1Task1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM1Task1Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM1Task1Query(baseOptions: Apollo.QueryHookOptions<M1Task1Query, M1Task1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M1Task1Query, M1Task1QueryVariables>(M1Task1Document, options);
      }
export function useM1Task1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M1Task1Query, M1Task1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M1Task1Query, M1Task1QueryVariables>(M1Task1Document, options);
        }
export type M1Task1QueryHookResult = ReturnType<typeof useM1Task1Query>;
export type M1Task1LazyQueryHookResult = ReturnType<typeof useM1Task1LazyQuery>;
export type M1Task1QueryResult = Apollo.QueryResult<M1Task1Query, M1Task1QueryVariables>;
export const M1Task2Document = gql`
    query M1Task2($lectionId: ID!, $groupId: ID!) {
  m1Task2(lection_id: $lectionId, group_id: $groupId) {
    user_id
    answer
    answer2
    my_comment
    my_points
  }
}
    `;

/**
 * __useM1Task2Query__
 *
 * To run a query within a React component, call `useM1Task2Query` and pass it any options that fit your needs.
 * When your component renders, `useM1Task2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM1Task2Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM1Task2Query(baseOptions: Apollo.QueryHookOptions<M1Task2Query, M1Task2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M1Task2Query, M1Task2QueryVariables>(M1Task2Document, options);
      }
export function useM1Task2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M1Task2Query, M1Task2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M1Task2Query, M1Task2QueryVariables>(M1Task2Document, options);
        }
export type M1Task2QueryHookResult = ReturnType<typeof useM1Task2Query>;
export type M1Task2LazyQueryHookResult = ReturnType<typeof useM1Task2LazyQuery>;
export type M1Task2QueryResult = Apollo.QueryResult<M1Task2Query, M1Task2QueryVariables>;
export const M1Task3Document = gql`
    query M1Task3($lectionId: ID!, $groupId: ID!) {
  m1Task3(lection_id: $lectionId, group_id: $groupId) {
    user_id
    answer
    answer2
    evaluation {
      advantages
      disadvantages
    }
  }
}
    `;

/**
 * __useM1Task3Query__
 *
 * To run a query within a React component, call `useM1Task3Query` and pass it any options that fit your needs.
 * When your component renders, `useM1Task3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM1Task3Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM1Task3Query(baseOptions: Apollo.QueryHookOptions<M1Task3Query, M1Task3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M1Task3Query, M1Task3QueryVariables>(M1Task3Document, options);
      }
export function useM1Task3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M1Task3Query, M1Task3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M1Task3Query, M1Task3QueryVariables>(M1Task3Document, options);
        }
export type M1Task3QueryHookResult = ReturnType<typeof useM1Task3Query>;
export type M1Task3LazyQueryHookResult = ReturnType<typeof useM1Task3LazyQuery>;
export type M1Task3QueryResult = Apollo.QueryResult<M1Task3Query, M1Task3QueryVariables>;
export const M1Task4Document = gql`
    query M1Task4($lectionId: ID!, $groupId: ID!) {
  m1Task4(lection_id: $lectionId, group_id: $groupId) {
    answer
    answer2
    evaluation_comments {
      user_id
      advantages
      disadvantages
      comment
      points
    }
    groupUncompleteTask
  }
}
    `;

/**
 * __useM1Task4Query__
 *
 * To run a query within a React component, call `useM1Task4Query` and pass it any options that fit your needs.
 * When your component renders, `useM1Task4Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM1Task4Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM1Task4Query(baseOptions: Apollo.QueryHookOptions<M1Task4Query, M1Task4QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M1Task4Query, M1Task4QueryVariables>(M1Task4Document, options);
      }
export function useM1Task4LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M1Task4Query, M1Task4QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M1Task4Query, M1Task4QueryVariables>(M1Task4Document, options);
        }
export type M1Task4QueryHookResult = ReturnType<typeof useM1Task4Query>;
export type M1Task4LazyQueryHookResult = ReturnType<typeof useM1Task4LazyQuery>;
export type M1Task4QueryResult = Apollo.QueryResult<M1Task4Query, M1Task4QueryVariables>;
export const M1ResultsDocument = gql`
    query M1Results($groupId: ID!, $lectionId: ID!) {
  m1Result(group_id: $groupId, lection_id: $lectionId) {
    answer
    answer2
    points
    cup_best_answer
    comments {
      user_id
      comment
      points
    }
    evaluations {
      user_id
      advantages
      disadvantages
    }
    evaluation_comments {
      answer
      answer2
      myEvaluation {
        advantages
        disadvantages
      }
      evaluation_comments {
        user_id
        comment
        points
      }
    }
    points_evaluation
    cup_best_comment
    points_activity
  }
}
    `;

/**
 * __useM1ResultsQuery__
 *
 * To run a query within a React component, call `useM1ResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useM1ResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM1ResultsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      lectionId: // value for 'lectionId'
 *   },
 * });
 */
export function useM1ResultsQuery(baseOptions: Apollo.QueryHookOptions<M1ResultsQuery, M1ResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M1ResultsQuery, M1ResultsQueryVariables>(M1ResultsDocument, options);
      }
export function useM1ResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M1ResultsQuery, M1ResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M1ResultsQuery, M1ResultsQueryVariables>(M1ResultsDocument, options);
        }
export type M1ResultsQueryHookResult = ReturnType<typeof useM1ResultsQuery>;
export type M1ResultsLazyQueryHookResult = ReturnType<typeof useM1ResultsLazyQuery>;
export type M1ResultsQueryResult = Apollo.QueryResult<M1ResultsQuery, M1ResultsQueryVariables>;
export const AddM3TasksDocument = gql`
    mutation AddM3Tasks($lectionId: ID!, $groupId: ID!, $taskNum: Int!, $answer: String!) {
  addM3Tasks(
    lection_id: $lectionId
    group_id: $groupId
    task_num: $taskNum
    answer: $answer
  ) {
    id
    message
  }
}
    `;
export type AddM3TasksMutationFn = Apollo.MutationFunction<AddM3TasksMutation, AddM3TasksMutationVariables>;

/**
 * __useAddM3TasksMutation__
 *
 * To run a mutation, you first call `useAddM3TasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM3TasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM3TasksMutation, { data, loading, error }] = useAddM3TasksMutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      taskNum: // value for 'taskNum'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAddM3TasksMutation(baseOptions?: Apollo.MutationHookOptions<AddM3TasksMutation, AddM3TasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM3TasksMutation, AddM3TasksMutationVariables>(AddM3TasksDocument, options);
      }
export type AddM3TasksMutationHookResult = ReturnType<typeof useAddM3TasksMutation>;
export type AddM3TasksMutationResult = Apollo.MutationResult<AddM3TasksMutation>;
export type AddM3TasksMutationOptions = Apollo.BaseMutationOptions<AddM3TasksMutation, AddM3TasksMutationVariables>;
export const AddM3Task4Document = gql`
    mutation AddM3Task4($lectionId: ID!, $groupId: ID!, $results: [M3Task4Input]!) {
  addM3Task4(lection_id: $lectionId, group_id: $groupId, results: $results) {
    id
    message
  }
}
    `;
export type AddM3Task4MutationFn = Apollo.MutationFunction<AddM3Task4Mutation, AddM3Task4MutationVariables>;

/**
 * __useAddM3Task4Mutation__
 *
 * To run a mutation, you first call `useAddM3Task4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM3Task4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM3Task4Mutation, { data, loading, error }] = useAddM3Task4Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      results: // value for 'results'
 *   },
 * });
 */
export function useAddM3Task4Mutation(baseOptions?: Apollo.MutationHookOptions<AddM3Task4Mutation, AddM3Task4MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM3Task4Mutation, AddM3Task4MutationVariables>(AddM3Task4Document, options);
      }
export type AddM3Task4MutationHookResult = ReturnType<typeof useAddM3Task4Mutation>;
export type AddM3Task4MutationResult = Apollo.MutationResult<AddM3Task4Mutation>;
export type AddM3Task4MutationOptions = Apollo.BaseMutationOptions<AddM3Task4Mutation, AddM3Task4MutationVariables>;
export const M3Task1Document = gql`
    query M3Task1($lectionId: ID!, $groupId: ID!) {
  m3Task1(lection_id: $lectionId, group_id: $groupId) {
    step1Answer
  }
}
    `;

/**
 * __useM3Task1Query__
 *
 * To run a query within a React component, call `useM3Task1Query` and pass it any options that fit your needs.
 * When your component renders, `useM3Task1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM3Task1Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM3Task1Query(baseOptions: Apollo.QueryHookOptions<M3Task1Query, M3Task1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M3Task1Query, M3Task1QueryVariables>(M3Task1Document, options);
      }
export function useM3Task1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M3Task1Query, M3Task1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M3Task1Query, M3Task1QueryVariables>(M3Task1Document, options);
        }
export type M3Task1QueryHookResult = ReturnType<typeof useM3Task1Query>;
export type M3Task1LazyQueryHookResult = ReturnType<typeof useM3Task1LazyQuery>;
export type M3Task1QueryResult = Apollo.QueryResult<M3Task1Query, M3Task1QueryVariables>;
export const M3Task2Document = gql`
    query M3Task2($lectionId: ID!, $groupId: ID!) {
  m3Task2(lection_id: $lectionId, group_id: $groupId) {
    step1Answer
    step2Answer
  }
}
    `;

/**
 * __useM3Task2Query__
 *
 * To run a query within a React component, call `useM3Task2Query` and pass it any options that fit your needs.
 * When your component renders, `useM3Task2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM3Task2Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM3Task2Query(baseOptions: Apollo.QueryHookOptions<M3Task2Query, M3Task2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M3Task2Query, M3Task2QueryVariables>(M3Task2Document, options);
      }
export function useM3Task2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M3Task2Query, M3Task2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M3Task2Query, M3Task2QueryVariables>(M3Task2Document, options);
        }
export type M3Task2QueryHookResult = ReturnType<typeof useM3Task2Query>;
export type M3Task2LazyQueryHookResult = ReturnType<typeof useM3Task2LazyQuery>;
export type M3Task2QueryResult = Apollo.QueryResult<M3Task2Query, M3Task2QueryVariables>;
export const M3Task3Document = gql`
    query M3Task3($lectionId: ID!, $groupId: ID!) {
  m3Task3(lection_id: $lectionId, group_id: $groupId) {
    step1Answer
    step2Answer
    step3Answer
  }
}
    `;

/**
 * __useM3Task3Query__
 *
 * To run a query within a React component, call `useM3Task3Query` and pass it any options that fit your needs.
 * When your component renders, `useM3Task3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM3Task3Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM3Task3Query(baseOptions: Apollo.QueryHookOptions<M3Task3Query, M3Task3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M3Task3Query, M3Task3QueryVariables>(M3Task3Document, options);
      }
export function useM3Task3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M3Task3Query, M3Task3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M3Task3Query, M3Task3QueryVariables>(M3Task3Document, options);
        }
export type M3Task3QueryHookResult = ReturnType<typeof useM3Task3Query>;
export type M3Task3LazyQueryHookResult = ReturnType<typeof useM3Task3LazyQuery>;
export type M3Task3QueryResult = Apollo.QueryResult<M3Task3Query, M3Task3QueryVariables>;
export const M3Task4Document = gql`
    query M3Task4($lectionId: ID!, $groupId: ID!) {
  m3Task4(lection_id: $lectionId, group_id: $groupId) {
    user_id
    answer
    step
    advantages
    disadvantages
    best
    worst
  }
}
    `;

/**
 * __useM3Task4Query__
 *
 * To run a query within a React component, call `useM3Task4Query` and pass it any options that fit your needs.
 * When your component renders, `useM3Task4Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM3Task4Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM3Task4Query(baseOptions: Apollo.QueryHookOptions<M3Task4Query, M3Task4QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M3Task4Query, M3Task4QueryVariables>(M3Task4Document, options);
      }
export function useM3Task4LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M3Task4Query, M3Task4QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M3Task4Query, M3Task4QueryVariables>(M3Task4Document, options);
        }
export type M3Task4QueryHookResult = ReturnType<typeof useM3Task4Query>;
export type M3Task4LazyQueryHookResult = ReturnType<typeof useM3Task4LazyQuery>;
export type M3Task4QueryResult = Apollo.QueryResult<M3Task4Query, M3Task4QueryVariables>;
export const M3ResultDocument = gql`
    query M3Result($lectionId: ID!, $groupId: ID!) {
  m3Result(lection_id: $lectionId, group_id: $groupId) {
    points
    points_activity
    cup_best_phrase
    dialog1 {
      userStep1 {
        ...userStepFields
      }
      nextUserStep2
      userStep3 {
        ...userStepFields
      }
    }
    dialog2 {
      prevUserStep1
      userStep2 {
        ...userStepFields
      }
      prevUserStep3
    }
  }
}
    ${UserStepFieldsFragmentDoc}`;

/**
 * __useM3ResultQuery__
 *
 * To run a query within a React component, call `useM3ResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useM3ResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM3ResultQuery({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM3ResultQuery(baseOptions: Apollo.QueryHookOptions<M3ResultQuery, M3ResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M3ResultQuery, M3ResultQueryVariables>(M3ResultDocument, options);
      }
export function useM3ResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M3ResultQuery, M3ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M3ResultQuery, M3ResultQueryVariables>(M3ResultDocument, options);
        }
export type M3ResultQueryHookResult = ReturnType<typeof useM3ResultQuery>;
export type M3ResultLazyQueryHookResult = ReturnType<typeof useM3ResultLazyQuery>;
export type M3ResultQueryResult = Apollo.QueryResult<M3ResultQuery, M3ResultQueryVariables>;
export const AddM4Task1Document = gql`
    mutation addM4Task1($lectionId: ID!, $groupId: ID!, $answer: String!) {
  addM4Task1(lection_id: $lectionId, group_id: $groupId, answer: $answer) {
    message
  }
}
    `;
export type AddM4Task1MutationFn = Apollo.MutationFunction<AddM4Task1Mutation, AddM4Task1MutationVariables>;

/**
 * __useAddM4Task1Mutation__
 *
 * To run a mutation, you first call `useAddM4Task1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM4Task1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM4Task1Mutation, { data, loading, error }] = useAddM4Task1Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAddM4Task1Mutation(baseOptions?: Apollo.MutationHookOptions<AddM4Task1Mutation, AddM4Task1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM4Task1Mutation, AddM4Task1MutationVariables>(AddM4Task1Document, options);
      }
export type AddM4Task1MutationHookResult = ReturnType<typeof useAddM4Task1Mutation>;
export type AddM4Task1MutationResult = Apollo.MutationResult<AddM4Task1Mutation>;
export type AddM4Task1MutationOptions = Apollo.BaseMutationOptions<AddM4Task1Mutation, AddM4Task1MutationVariables>;
export const AddM4Task2Document = gql`
    mutation addM4Task2($lectionId: ID!, $groupId: ID!, $results: [M4Task2Input]!) {
  addM4Task2(lection_id: $lectionId, group_id: $groupId, results: $results) {
    message
  }
}
    `;
export type AddM4Task2MutationFn = Apollo.MutationFunction<AddM4Task2Mutation, AddM4Task2MutationVariables>;

/**
 * __useAddM4Task2Mutation__
 *
 * To run a mutation, you first call `useAddM4Task2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM4Task2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM4Task2Mutation, { data, loading, error }] = useAddM4Task2Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      results: // value for 'results'
 *   },
 * });
 */
export function useAddM4Task2Mutation(baseOptions?: Apollo.MutationHookOptions<AddM4Task2Mutation, AddM4Task2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM4Task2Mutation, AddM4Task2MutationVariables>(AddM4Task2Document, options);
      }
export type AddM4Task2MutationHookResult = ReturnType<typeof useAddM4Task2Mutation>;
export type AddM4Task2MutationResult = Apollo.MutationResult<AddM4Task2Mutation>;
export type AddM4Task2MutationOptions = Apollo.BaseMutationOptions<AddM4Task2Mutation, AddM4Task2MutationVariables>;
export const ViewM4MessageDocument = gql`
    mutation viewM4Message($lectionId: ID!, $groupId: ID!, $messageId: ID!) {
  viewM4Message(
    lection_id: $lectionId
    group_id: $groupId
    message_id: $messageId
  ) {
    message
  }
}
    `;
export type ViewM4MessageMutationFn = Apollo.MutationFunction<ViewM4MessageMutation, ViewM4MessageMutationVariables>;

/**
 * __useViewM4MessageMutation__
 *
 * To run a mutation, you first call `useViewM4MessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewM4MessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewM4MessageMutation, { data, loading, error }] = useViewM4MessageMutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useViewM4MessageMutation(baseOptions?: Apollo.MutationHookOptions<ViewM4MessageMutation, ViewM4MessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewM4MessageMutation, ViewM4MessageMutationVariables>(ViewM4MessageDocument, options);
      }
export type ViewM4MessageMutationHookResult = ReturnType<typeof useViewM4MessageMutation>;
export type ViewM4MessageMutationResult = Apollo.MutationResult<ViewM4MessageMutation>;
export type ViewM4MessageMutationOptions = Apollo.BaseMutationOptions<ViewM4MessageMutation, ViewM4MessageMutationVariables>;
export const SendM4MessageDocument = gql`
    mutation sendM4Message($lectionId: ID!, $groupId: ID!, $text: String!, $isProposed: Boolean!) {
  sendM4Message(
    lection_id: $lectionId
    group_id: $groupId
    text: $text
    is_proposed: $isProposed
  ) {
    message
  }
}
    `;
export type SendM4MessageMutationFn = Apollo.MutationFunction<SendM4MessageMutation, SendM4MessageMutationVariables>;

/**
 * __useSendM4MessageMutation__
 *
 * To run a mutation, you first call `useSendM4MessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendM4MessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendM4MessageMutation, { data, loading, error }] = useSendM4MessageMutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      text: // value for 'text'
 *      isProposed: // value for 'isProposed'
 *   },
 * });
 */
export function useSendM4MessageMutation(baseOptions?: Apollo.MutationHookOptions<SendM4MessageMutation, SendM4MessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendM4MessageMutation, SendM4MessageMutationVariables>(SendM4MessageDocument, options);
      }
export type SendM4MessageMutationHookResult = ReturnType<typeof useSendM4MessageMutation>;
export type SendM4MessageMutationResult = Apollo.MutationResult<SendM4MessageMutation>;
export type SendM4MessageMutationOptions = Apollo.BaseMutationOptions<SendM4MessageMutation, SendM4MessageMutationVariables>;
export const VoteM4MessageDocument = gql`
    mutation voteM4Message($lectionId: ID!, $groupId: ID!, $messageId: ID!, $userId: ID!) {
  voteM4Message(
    lection_id: $lectionId
    group_id: $groupId
    message_id: $messageId
    user_id: $userId
  ) {
    message
  }
}
    `;
export type VoteM4MessageMutationFn = Apollo.MutationFunction<VoteM4MessageMutation, VoteM4MessageMutationVariables>;

/**
 * __useVoteM4MessageMutation__
 *
 * To run a mutation, you first call `useVoteM4MessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteM4MessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteM4MessageMutation, { data, loading, error }] = useVoteM4MessageMutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVoteM4MessageMutation(baseOptions?: Apollo.MutationHookOptions<VoteM4MessageMutation, VoteM4MessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteM4MessageMutation, VoteM4MessageMutationVariables>(VoteM4MessageDocument, options);
      }
export type VoteM4MessageMutationHookResult = ReturnType<typeof useVoteM4MessageMutation>;
export type VoteM4MessageMutationResult = Apollo.MutationResult<VoteM4MessageMutation>;
export type VoteM4MessageMutationOptions = Apollo.BaseMutationOptions<VoteM4MessageMutation, VoteM4MessageMutationVariables>;
export const AddM4Task4Document = gql`
    mutation addM4Task4($lectionId: ID!, $groupId: ID!, $characteristics: [M4Task2Input]!, $groupAnswers: [M4Task4Input]!) {
  addM4Task4(
    lection_id: $lectionId
    group_id: $groupId
    characteristics: $characteristics
    group_answers: $groupAnswers
  ) {
    message
  }
}
    `;
export type AddM4Task4MutationFn = Apollo.MutationFunction<AddM4Task4Mutation, AddM4Task4MutationVariables>;

/**
 * __useAddM4Task4Mutation__
 *
 * To run a mutation, you first call `useAddM4Task4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM4Task4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM4Task4Mutation, { data, loading, error }] = useAddM4Task4Mutation({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      characteristics: // value for 'characteristics'
 *      groupAnswers: // value for 'groupAnswers'
 *   },
 * });
 */
export function useAddM4Task4Mutation(baseOptions?: Apollo.MutationHookOptions<AddM4Task4Mutation, AddM4Task4MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddM4Task4Mutation, AddM4Task4MutationVariables>(AddM4Task4Document, options);
      }
export type AddM4Task4MutationHookResult = ReturnType<typeof useAddM4Task4Mutation>;
export type AddM4Task4MutationResult = Apollo.MutationResult<AddM4Task4Mutation>;
export type AddM4Task4MutationOptions = Apollo.BaseMutationOptions<AddM4Task4Mutation, AddM4Task4MutationVariables>;
export const M4Task1Document = gql`
    query m4Task1($lectionId: ID!, $groupId: ID!) {
  m4Task1(lection_id: $lectionId, group_id: $groupId) {
    answer
  }
}
    `;

/**
 * __useM4Task1Query__
 *
 * To run a query within a React component, call `useM4Task1Query` and pass it any options that fit your needs.
 * When your component renders, `useM4Task1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM4Task1Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM4Task1Query(baseOptions: Apollo.QueryHookOptions<M4Task1Query, M4Task1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M4Task1Query, M4Task1QueryVariables>(M4Task1Document, options);
      }
export function useM4Task1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M4Task1Query, M4Task1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M4Task1Query, M4Task1QueryVariables>(M4Task1Document, options);
        }
export type M4Task1QueryHookResult = ReturnType<typeof useM4Task1Query>;
export type M4Task1LazyQueryHookResult = ReturnType<typeof useM4Task1LazyQuery>;
export type M4Task1QueryResult = Apollo.QueryResult<M4Task1Query, M4Task1QueryVariables>;
export const M4Task2Document = gql`
    query m4Task2($lectionId: ID!, $groupId: ID!) {
  m4Task2(lection_id: $lectionId, group_id: $groupId) {
    user_id
    answer
    advantages
    disadvantages
    points
  }
}
    `;

/**
 * __useM4Task2Query__
 *
 * To run a query within a React component, call `useM4Task2Query` and pass it any options that fit your needs.
 * When your component renders, `useM4Task2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM4Task2Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM4Task2Query(baseOptions: Apollo.QueryHookOptions<M4Task2Query, M4Task2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M4Task2Query, M4Task2QueryVariables>(M4Task2Document, options);
      }
export function useM4Task2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M4Task2Query, M4Task2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M4Task2Query, M4Task2QueryVariables>(M4Task2Document, options);
        }
export type M4Task2QueryHookResult = ReturnType<typeof useM4Task2Query>;
export type M4Task2LazyQueryHookResult = ReturnType<typeof useM4Task2LazyQuery>;
export type M4Task2QueryResult = Apollo.QueryResult<M4Task2Query, M4Task2QueryVariables>;
export const M4Task3Document = gql`
    query m4Task3($lectionId: ID!, $groupId: ID!, $lastViewed: ID) {
  m4Task3(lection_id: $lectionId, group_id: $groupId, last_viewed: $lastViewed) {
    last_viewed
    max_votes
    count_unviewed
    is_chat_closed
    userColor
    messages {
      id
      user_id
      color
      text
      is_proposed
      is_voted
      is_group_answer
      votes
      date
    }
  }
}
    `;

/**
 * __useM4Task3Query__
 *
 * To run a query within a React component, call `useM4Task3Query` and pass it any options that fit your needs.
 * When your component renders, `useM4Task3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM4Task3Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *      lastViewed: // value for 'lastViewed'
 *   },
 * });
 */
export function useM4Task3Query(baseOptions: Apollo.QueryHookOptions<M4Task3Query, M4Task3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M4Task3Query, M4Task3QueryVariables>(M4Task3Document, options);
      }
export function useM4Task3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M4Task3Query, M4Task3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M4Task3Query, M4Task3QueryVariables>(M4Task3Document, options);
        }
export type M4Task3QueryHookResult = ReturnType<typeof useM4Task3Query>;
export type M4Task3LazyQueryHookResult = ReturnType<typeof useM4Task3LazyQuery>;
export type M4Task3QueryResult = Apollo.QueryResult<M4Task3Query, M4Task3QueryVariables>;
export const M4Task4Document = gql`
    query m4Task4($lectionId: ID!, $groupId: ID!) {
  m4Task4(lection_id: $lectionId, group_id: $groupId) {
    group_answers {
      user_id
      answer
      points
    }
    characteristics {
      user_id
      color
      advantages
      disadvantages
      points
    }
  }
}
    `;

/**
 * __useM4Task4Query__
 *
 * To run a query within a React component, call `useM4Task4Query` and pass it any options that fit your needs.
 * When your component renders, `useM4Task4Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM4Task4Query({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM4Task4Query(baseOptions: Apollo.QueryHookOptions<M4Task4Query, M4Task4QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M4Task4Query, M4Task4QueryVariables>(M4Task4Document, options);
      }
export function useM4Task4LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M4Task4Query, M4Task4QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M4Task4Query, M4Task4QueryVariables>(M4Task4Document, options);
        }
export type M4Task4QueryHookResult = ReturnType<typeof useM4Task4Query>;
export type M4Task4LazyQueryHookResult = ReturnType<typeof useM4Task4LazyQuery>;
export type M4Task4QueryResult = Apollo.QueryResult<M4Task4Query, M4Task4QueryVariables>;
export const M4ResultDocument = gql`
    query M4Result($lectionId: ID!, $groupId: ID!) {
  m4Result(lection_id: $lectionId, group_id: $groupId) {
    points
    points_activity
    answer
    answer_points
    answer_characteristics {
      advantages
      disadvantages
      points
    }
    group_answer
    group_answer_points
    team_work_characteristics {
      advantages
      disadvantages
      points
    }
    team_work_points
  }
}
    `;

/**
 * __useM4ResultQuery__
 *
 * To run a query within a React component, call `useM4ResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useM4ResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM4ResultQuery({
 *   variables: {
 *      lectionId: // value for 'lectionId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useM4ResultQuery(baseOptions: Apollo.QueryHookOptions<M4ResultQuery, M4ResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M4ResultQuery, M4ResultQueryVariables>(M4ResultDocument, options);
      }
export function useM4ResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M4ResultQuery, M4ResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M4ResultQuery, M4ResultQueryVariables>(M4ResultDocument, options);
        }
export type M4ResultQueryHookResult = ReturnType<typeof useM4ResultQuery>;
export type M4ResultLazyQueryHookResult = ReturnType<typeof useM4ResultLazyQuery>;
export type M4ResultQueryResult = Apollo.QueryResult<M4ResultQuery, M4ResultQueryVariables>;